import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import React from "react";
import PaymentForm from "./PaymentForm";

const PUBLIC_KEY = "pk_live_pZYsnFtxnq837oXEZtU97mU0";

const stripeTestPromise = loadStripe(PUBLIC_KEY);

const StripeButton = ({ cartId, shippmentDetail, total }) => {
  return (
    <>
      <div className="flex flex-row items-center justify-between py-4">
        <div className="flex flex-row space-x-4 items-center justify-center">
          <img
            src="/images/footer/visa.png"
            className="x-sm:h-6 sm:h-10"
            alt="icon"
          />
          <img
            src="/images/footer/mastercard.png"
            className="x-sm:h-3 sm:h-5"
            alt="icon"
          />
        </div>
        <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
          الدفع عبر
        </p>
      </div>
      <Elements stripe={stripeTestPromise}>
        <PaymentForm total={total} cartId={cartId} shippmentDetail={shippmentDetail} />
      </Elements>
    </>
  );
};

export default StripeButton;
