import React, { useState } from "react";
import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";
import { addComment } from "../../services/moduleComments";
import { getTheUserFromStorage } from "./../../services/auth";
import { deleteComment } from "../../services/moduleComments";
import { editComment } from "../../services/moduleComments";
import {
  addResponse,
  deleteResponse,
  editResponse,
} from "../../services/moduleComments";
import { CgMenuMotion } from "react-icons/cg";

const Reviews = ({ courseId, moduleId, setLoading }) => {
  const [comment, setComment] = useState("");
  const [response, setResponse] = useState("");
  const [newComment, setNewComment] = useState("");
  const [openEditComment, setOpenEditComment] = useState(null);
  const [respondComment, setRespondComment] = useState(null);
  const [editRespondComment, setEditRespondComment] = useState(null);
  const [toggleMenu, setToggleMenu] = useState(null);

  const { data, error } = useSWR(
    `https://farasha.smartiniaclient.com/comments/all/?module=${moduleId}`,
    fetcher
  );

  return (
    <div className="py-16">
      <div className="text-right text-base text-gray-200   x-sm:leading-loose md:leading-loose  lg:leading-loose  2xl:leading-loose w-11/12 mx-auto">
        <h1 className="text-orange font-extrabold  text-center text-2xl lg:text-4xl 2xl:text-5xl  mb-4">
          تعليقات
        </h1>
        {data &&
          data.map((item) => (
            <div className="border-bottom-grey" key={item.id}>
              <div className="py-8 flex flex-col-reverse items-end justify-end space-x-3">
                <div className="flex flex-30 flex-wrap items-center  mt-2 md:mt-8 space-x-1 md:space-x-4">
                  <button
                    className="bg-gradient-to-r from-purple-900 to-pink-400 text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-8 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl outline-none focus:outline-none min-w-50"
                    onClick={async () => {
                      if (respondComment !== item.id) {
                        setRespondComment(item.id);
                      } else {
                        setLoading(true);
                        setLoading(true);
                        const res = await addResponse({
                          user: getTheUserFromStorage().user_id,
                          parent: item.id,
                          body: response,
                        });
                        mutate(
                          `https://farasha.smartiniaclient.com/comments/all/?module=${moduleId}`
                        );
                        setResponse("");
                        setRespondComment(null);
                        setLoading(false);
                      }
                    }}>
                    {respondComment === item.id
                      ? "تأكيد الرد"
                      : "الرد على التعليق"}
                  </button>
                  {item.user.id === getTheUserFromStorage().user_id && (
                    <>
                      <button
                        className=" bg-gradient-to-r from-purple-900 to-pink-400 text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-8 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl outline-none focus:outline-none min-w-50"
                        onClick={async () => {
                          setLoading(true);
                          const res = await deleteComment(item.id);
                          mutate(
                            `https://farasha.smartiniaclient.com/comments/all/?module=${moduleId}`,
                            data.filter((book) => book.id !== item.id),
                            false
                          );

                          setComment("");
                          setLoading(false);
                        }}>
                        حدف
                      </button>
                      <button
                        onClick={async () => {
                          if (openEditComment !== item.id) {
                            setNewComment(item.body);
                            setOpenEditComment(item.id);
                          } else {
                            setLoading(true);
                            const res = await editComment({
                              commentId: item.id,
                              newComment: newComment,
                            });
                            mutate(
                              `https://farasha.smartiniaclient.com/comments/all/?module=${moduleId}`
                            );
                            setNewComment("");
                            setOpenEditComment(null);
                            setLoading(false);
                          }
                        }}
                        className=" bg-gradient-to-r from-purple-900 to-pink-400 text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-8 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl outline-none focus:outline-none min-w-50">
                        {openEditComment === item.id ? "تأكيد" : "تعديل"}
                      </button>
                    </>
                  )}
                </div>

                <div className="w-full ">
                  {openEditComment !== item.id && (
                    <p className="x-sm:text-base text-gray-700 lg:text-2xl mt-4">
                      {item.body}
                    </p>
                  )}
                  {item.answers.length > 0 && (
                    <div className="mx-8 mt-4">
                      <h1 className="font-bold">: الردود على التعليق</h1>
                      {item.answers.map((answer) => (
                        <div className="border-bottom-grey mt-4">
                          <div
                            className={`flex mb-4 ${
                              answer.user.id === getTheUserFromStorage().user_id
                                ? "justify-between"
                                : "justify-end"
                            }`}>
                            {answer.user.id ===
                              getTheUserFromStorage().user_id && (
                              <div className="relative">
                                <button
                                  onClick={() => {
                                    if (toggleMenu !== answer.id) {
                                      setToggleMenu(answer.id);
                                    } else {
                                      setToggleMenu(null);
                                    }
                                  }}>
                                  <CgMenuMotion className="h-3 w-3 md:w-6 md:h-6 outline-none focus:outline-none " />
                                </button>
                                {toggleMenu === answer.id && (
                                  <div className="absolute flex flex-col ">
                                    <button
                                      onClick={async () => {
                                        setLoading(true);
                                        await deleteResponse(answer.id);
                                        mutate(
                                          `https://farasha.smartiniaclient.com/comments/all/?module=${moduleId}`
                                        );
                                        setLoading(false);
                                      }}
                                      className="text-xs md:text-base p-2 bg-reghda-black  text-white rounded-t-lg">
                                      حدف
                                    </button>
                                    <button
                                      onClick={async () => {
                                        if (editRespondComment !== answer.id) {
                                          setResponse(answer.body);
                                          setEditRespondComment(answer.id);
                                        } else {
                                          setLoading(true);
                                          await editResponse({
                                            responseId: answer.id,
                                            body: response,
                                          });
                                          mutate(
                                            `https://farasha.smartiniaclient.com/comments/all/?module=${moduleId}`
                                          );
                                          setResponse("");
                                          setEditRespondComment(null);
                                          setLoading(false);
                                        }
                                      }}
                                      className="text-xs md:text-base p-2 bg-reghda-black  text-white rounded-b-lg">
                                      {editRespondComment === answer.id
                                        ? "تأكيد"
                                        : "تعديل"}
                                    </button>
                                  </div>
                                )}
                              </div>
                            )}
                            <div className="flex justify-end items-center space-x-4 space-y-2">
                              <span className="text-xs text-gray-700 md:text-sm font-bold">
                                {answer.user.username}
                              </span>
                              <img
                                src={
                                  answer.user.image
                                    ? answer.user.image
                                    : "/images/admin/default-user.png"
                                }
                                alt="profile"
                                className="rounded-full x-sm:w-6 x-sm:h-6  md:w-8 md:h-8"
                                style={{ margin: "0 0 0 0.5rem " }}
                              />
                            </div>
                          </div>

                          <div className={`flex mb-4 justify-end`}>
                            {editRespondComment !== answer.id ? (
                              <span className="text-xs text-gray-700 md:text-sm">
                                {answer.body}
                              </span>
                            ) : (
                              <div className="space-y-4 mt-4">
                                <div className="w-full  mb-4">
                                  <span className="text-xl">
                                    : الرد على التعليق
                                  </span>
                                  <textarea
                                    onChange={(event) =>
                                      setResponse(event.target.value)
                                    }
                                    value={response}
                                    placeholder="الرد على التعليق"
                                    rows="3"
                                    id="text"
                                    type="text"
                                    class="w-full border-2 border-gray-200 bg-white text-right text-2xl p-3 2xl:p-9 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
                                  />
                                </div>
                              </div>
                            )}
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                  {respondComment === item.id && (
                    <div className="space-y-4 mt-4">
                      <div className="w-full  mb-4">
                        <span className="text-xl">: الرد على التعليق</span>
                        <textarea
                          onChange={(event) => setResponse(event.target.value)}
                          value={response}
                          placeholder="الرد على التعليق"
                          rows="3"
                          id="text"
                          type="text"
                          class="w-full border-2 border-gray-200 bg-white text-right text-2xl p-3 2xl:p-9 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
                        />
                      </div>
                    </div>
                  )}
                  {item.user.id === getTheUserFromStorage().user_id &&
                    openEditComment === item.id && (
                      <div className="space-y-4">
                        <div className="w-full  mb-4">
                          <span className="text-xl">: التعليق</span>
                          <textarea
                            onChange={(event) =>
                              setNewComment(event.target.value)
                            }
                            value={newComment}
                            placeholder="تعليق"
                            rows="3"
                            id="text"
                            type="text"
                            class="w-full border-2 border-gray-200 bg-white text-right text-2xl p-3 2xl:p-9 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
                          />
                        </div>
                      </div>
                    )}
                </div>

                <div className=" flex flex-col items-center justify-center space-y-2 space-x-4">
                  <div className="flex items-center space-x-4">
                    <div>
                      <p
                        style={{ margin: 0 }}
                        className="text-center  text-orange font-extrabold x-sm:text-sm sm:text-base ">{`${item.user.given_name} ${item.user.family_name}`}</p>
                      <p className="x-sm:text-sm sm:text-sm">
                        {/*   {item.created_at.slice(0, 10)} */}
                      </p>
                    </div>
                    <img
                      style={{ marginTop: 0 }}
                      className="rounded-full x-sm:w-8 x-sm:h-8 sm:w-12 sm:h-12 md:w-16 md:h-16 2xl:w-16 2xl:h-16 picfit"
                      alt="user"
                      src={
                        item.user.image
                          ? item.user.image
                          : "/images/admin/default-user.png"
                      }
                    />
                  </div>
                </div>
              </div>
            </div>
          ))}
      </div>
      <div className="pt-4 pb-20 text-right text-base text-gray-200 x-sm:leading-loose md:leading-loose  lg:leading-loose  2xl:leading-loose w-11/12 mx-auto">
        <div className="py-8 flex flex-col-reverse md:flex-row items-center justify-center md:space-x-4 lg:space-x-8">
          <div className="x-sm:w-full md:w-1/5">
            <div className="w-full flex flex-row items-center justify-center">
              <button
                onClick={async () => {
                  if (comment.length < 1) {
                    return;
                  }
                  setLoading(true);
                  let res = await addComment({
                    user: getTheUserFromStorage().user_id,
                    course: courseId,
                    module: moduleId,
                    body: comment,
                  });
                  if (res && res.status === 201) {
                    mutate(
                      `https://farasha.smartiniaclient.com/comments/all/?module=${moduleId}`
                    );
                  } else {
                    alert("something wrong!");
                  }
                  setComment("");
                  setLoading(false);
                }}
                className="bg-gradient-to-r from-purple-900 to-pink-400 text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-16 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-2xl 2xl:text-5xl outline-none focus:outline-none">
                أضف
              </button>
            </div>
          </div>
          <div className="w-full md:w-4/5">
            <textarea
              onChange={(event) => setComment(event.target.value)}
              value={comment}
              placeholder="تعليق"
              rows="3"
              id="text"
              type="text"
              class="w-full border-2 border-gray-200 bg-white text-gray-500 text-right md:text-2xl p-3 2xl:p-9 pr-6 rounded-2xl outline-none focus:outline-none"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Reviews;
