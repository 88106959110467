import React from "react";
import Header from "./../Header";
import Footer from "./../Footer";
import Orders from "./Orders";

const OrdersPage = () => {
  return (
    <div>
      <Header />
      <Orders />
      <Footer />
    </div>
  );
};

export default OrdersPage;
