import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
} from "swiper/core";
import { ReactComponent as SvgAboutUs } from "../../../assets/titlesSvgAboutUs.svg";
import useSWR from "swr";
import { fetcher } from "../../../services/fetcher";
import "swiper/swiper-bundle.css";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";

const SwiperComponent = () => {
  SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);

  const { data, error } = useSWR(
    `https://farasha.smartiniaclient.com/about/sayings/`,
    fetcher
  );

  return (
    <>
      <div className="text-center font-bold  md:mb-8 mt-8 md:mt-0 text-base  relative ">
        <SvgAboutUs className="mx-auto w-3/4 md:w-1/2 2xl:w-1/3 h-auto " />
      </div>
      <Swiper
        spaceBetween={30}
        slidesPerView={3}
        navigation
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          // when window width is >= 640px
          750: {
            slidesPerView: 2,
          },
          // when window width is >= 850px
          1050: {
            slidesPerView: 3,
          },
        }}
        loop={true}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        pagination={{ clickable: true, dynamicBullets: true }}>
        {data &&
          data.map((item, index) => (
            <SwiperSlide>
              <div className="flex  justify-center relative">
                <img
                  src="images/opinionSvg.png"
                  alt="opinion"
                  className="min-width-250 mb-4"
                />
                <div className="absolute w-6/12 h-16">
                  <img
                    style={{ width: 79, height: 79 }}
                    className="rounded-full picfit mx-auto block"
                    src={item.image}
                    alt="profile_pic"
                  />
                </div>
                <div className="text-farasha-purple  absolute w-7/12 h-16 top-1/4 lg:top-22-100 text-center text-xxs xm:text-xs leading-5  2xl:leading-8">
                  <h1 className="font-bold text-xl mb-2">{item.author}</h1>
                  <p className="rtl">
                    <span>{item.body.slice(0, 280)}</span>
                    {item.body.length > 280 && <span>...</span>}
                  </p>
                </div>
              </div>
            </SwiperSlide>
          ))}
      </Swiper>
    </>
  );
};

export default SwiperComponent;
