import React, { useState } from "react";
import Header from "./../Header";
import Footer from "./../Footer";
import { Link } from "react-router-dom";

import { IconButton } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

const OurAgents = () => {
  return (
    <div>
      <Header></Header>
      <div className="h-full text-center p-16 flex flex-col items-center justify-center x-sm:space-y-4 md:space-y-8 2xl:space-y-16 text-gray-600">
        <h1 className="font-extrabold text-orange x-sm:text-xs sm:text-base md:text-xl lg:text-2xl 2xl:text-5xl">
          وكلائنا
        </h1>
      </div>
      <div className="text-center h-full flex flex-col space-y-10 md:space-y-0 x-sm:items-center md:flex-row md:space-x-48 md:px-20 pb-16">
        {[
          { title: "في تونس", picture: "/images/tu.png", c: "تونس" },
          { title: "في المغرب", picture: "/images/mc.png", c: "المغرب" },
        ].map((item) => (
          <div className="w-full md:w-1/2 flex flex-col items-center justify-center space-y-10 text-gray-600">
            {/* <div className="w-full md:mb-10">
              <div className="w-full flex flex-row items-center justify-center">
                <div className="flex flex-row items-center justify-center w-1/2 h-12 2xl:p-8 text-center bg-orange text-white rounded-bl-3xl rounded-tr-3xl mx-sm:text-sm md:text-xl 2xl:text-2xl font-extrabold">
                  {item.title}
                </div>
              </div>
            </div> */}
            <img
              className="x-sm:w-36 x-sm:h-36 md:w-64 md:h-64 lg:w-about-pics lg:h-about-pics 2xl:w-about-pics-xl 2xl:h-about-pics-xl object-cover"
              //   src="/images/tu.png"
              src={item.picture}
              alt="Layer"
            />
            <div className="p-2 md:p-0 text-right x-sm:text-xs md:text-base lg:text-xl 2xl:text-3xl lg:leading-relaxed 2xl:leading-loose tracking-wide">
              <p>
                يمكنك تواصل مع وكلائنا في {`${item.c}`} من خلال ضغط على رابط
                الواتس اب التالي
              </p>
            </div>
            <a
              href="https://api.whatsapp.com/send?phone=213558318841"
              target="_blank"
              rel="noreferrer"
              className="cursor-pointer text-white bg-green-whatsapp px-4 flex flex-row items-center justify-center font-extrabold x-sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl rounded-xl"
            >
              <IconButton style={{ color: "white" }}>
                <WhatsAppIcon />
              </IconButton>
              <span>رابط</span>
            </a>
          </div>
        ))}
      </div>

      <Footer></Footer>
    </div>
  );
};

export default OurAgents;
