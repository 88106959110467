import React, { useContext } from "react";
import { storeContext } from "./../global/store";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import ScrollToTop from "./scrollToTop";
import { LastLocationProvider } from "react-router-last-location";

import UserActivation from "./../components/UserActivation/UserActivation";
import UserReactivation from "./../components/UserActivation/UserReactivation";
import ResetPassword from "./../components/ResetPassword/Page";

import Login from "./../components/Login";
import Signup from "./../components/Signup";

import HomePage from "../components/HomePage/Page";

import ArticlesPage from "./../components/articles/Page";
import ArticleContentPage from "./../components/article-content/Page";

import FreeCoursesPage from "./../components/free-courses/Page";
import PaidCoursesPage from "./../components/paid-courses/Page";
import CourseDetailPage from "./../components/course-detail/Page";
import CourseStudyPage from "./../components/course-study/Page";
import PackDetail from "./../components/pack-detail/Page";
import PackCourses from "./../components/pack-courses/Page";

import SettingsPage from "./../components/settings/Page";
import MyCoursePage from "./../components/my-courses/Page";
import OrdersPage from "./../components/my-orders/Page";
import PackOrders from "./../components/my-pack-orders/Page";

import CartPage from "./../components/cart/Page";
import CheckOutPage from "./../components/check-out/Page";
import PackCheckOutPage from "./../components/pack-check-out/Page";

import ConditionsPage from "../components/policy-pages/Conditions";
import PrivacyPolicyPage from "./../components/policy-pages/PrivacyPolicy";
import RefundPolicyPage from "./../components/policy-pages/RefundPolicy";
import QuestionsPage from "./../components/policy-pages/Questions";

import AboutUs from "../components/aboutUs/Page";

import { IconButton } from "@material-ui/core";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import OurAgents from "../components/our-agents/Page";

function App() {
  const { store } = useContext(storeContext);

  return (
    <React.Fragment>
      <Router>
        <LastLocationProvider>
          <ScrollToTop />
          <Switch>
            <Route
              path="/user_activation"
              exact
              render={(props) => <UserActivation {...props}></UserActivation>}
            ></Route>
            <Route
              path="/user_reactivation"
              exact
              render={(props) => (
                <UserReactivation {...props}></UserReactivation>
              )}
            ></Route>
            <Route
              path="/reset_password"
              exact
              render={(props) => <ResetPassword {...props}></ResetPassword>}
            ></Route>
            <Route
              path="/reset_password/:userId/:token"
              exact
              render={(props) => <ResetPassword {...props}></ResetPassword>}
            ></Route>

            <Route
              path="/signup"
              exact
              render={(props) => <Signup {...props}></Signup>}
            ></Route>
            <Route
              path="/login"
              exact
              render={(props) => <Login overview {...props}></Login>}
            ></Route>
            <Route
              path="/"
              exact
              render={(props) => <HomePage {...props}></HomePage>}
            ></Route>
            <Route
              path="/about-us"
              exact
              render={(props) => <AboutUs {...props}></AboutUs>}
            ></Route>
            <Route
              path="/articles"
              exact
              render={(props) => <ArticlesPage {...props}></ArticlesPage>}
            ></Route>
            {/*             <Route
              path="/pack-detail/:slug"
              exact
              render={(props) => <PackDetail {...props}></PackDetail>}
            ></Route> */}
            {/*    <Route
              path="/pack-courses"
              exact
              render={(props) => <PackCourses {...props}></PackCourses>}
            ></Route> */}

            <Route
              exact
              path="/articles/:slug"
              render={(props) => (
                <ArticleContentPage {...props}></ArticleContentPage>
              )}
            ></Route>
            <ProtectedRoute
              isLogged={store.isLogged}
              path="/my-pack-orders"
              exact
              render={(props) => <PackOrders {...props}></PackOrders>}
            ></ProtectedRoute>

            <Route
              path="/free-courses"
              exact
              render={(props) => <FreeCoursesPage {...props}></FreeCoursesPage>}
            ></Route>
            <Route
              path="/paid-courses"
              exact
              render={(props) => <PaidCoursesPage {...props}></PaidCoursesPage>}
            ></Route>

            <Route
              path="/paid-courses/course-detail/:slug"
              exact
              render={(props) => (
                <CourseDetailPage {...props}></CourseDetailPage>
              )}
            ></Route>

            <ProtectedRoute
              isLogged={store.isLogged}
              path="/course/:slug"
              exact
              render={(props) => <CourseStudyPage {...props}></CourseStudyPage>}
            ></ProtectedRoute>

            <ProtectedRoute
              isLogged={store.isLogged}
              path="/settings"
              exact
              render={(props) => <SettingsPage {...props}></SettingsPage>}
            ></ProtectedRoute>

            <ProtectedRoute
              isLogged={store.isLogged}
              path="/my-courses"
              exact
              render={(props) => <MyCoursePage {...props}></MyCoursePage>}
            ></ProtectedRoute>

            <ProtectedRoute
              isLogged={store.isLogged}
              path="/my-orders"
              exact
              render={(props) => <OrdersPage {...props}></OrdersPage>}
            ></ProtectedRoute>

            <ProtectedRoute
              isLogged={store.isLogged}
              path="/cart"
              exact
              render={(props) => <CartPage {...props}></CartPage>}
            ></ProtectedRoute>

            <ProtectedRoute
              isLogged={store.isLogged}
              path="/check-out"
              exact
              render={(props) => <CheckOutPage {...props}></CheckOutPage>}
            ></ProtectedRoute>

            {/* <ProtectedRoute
              isLogged={store.isLogged}
              path="/pack-check-out"
              exact
              render={(props) => (
                <PackCheckOutPage {...props}></PackCheckOutPage>
              )}
            ></ProtectedRoute> */}

            {/*             <Route
              isLogged={store.isLogged}
              path="/pack-check-out"
              exact
              render={(props) => (
                <PackCheckOutPage {...props}></PackCheckOutPage>
              )}
            ></Route> */}

            <Route
              path="/questions"
              exact
              render={(props) => <QuestionsPage {...props}></QuestionsPage>}
            ></Route>
            <Route
              path="/return"
              exact
              render={(props) => (
                <RefundPolicyPage {...props}></RefundPolicyPage>
              )}
            ></Route>
            <Route
              path="/privacy-policy"
              exact
              render={(props) => (
                <PrivacyPolicyPage {...props}></PrivacyPolicyPage>
              )}
            ></Route>

            <Route
              path="/rules"
              exact
              render={(props) => <ConditionsPage {...props}></ConditionsPage>}
            ></Route>
            <Route
              path="/our-agents"
              exact
              render={(props) => <OurAgents {...props}></OurAgents>}
            ></Route>
            <Redirect to="/"></Redirect>
          </Switch>
        </LastLocationProvider>
      </Router>
      <a
        href="https://api.whatsapp.com/send?phone=213558318841"
        className="whatsapp flex flex-row items-start justify-center"
        target="_blank"
        rel="noreferrer"
      >
        <IconButton style={{ color: "white" }}>
          <WhatsAppIcon />
        </IconButton>
      </a>
    </React.Fragment>
  );
}

export default App;
