import React, { useState, useContext, useEffect } from "react";
import { storeContext } from "./../../global/store";
import CourseCard from "../CourseCard";
import { useParams, useLocation, useHistory } from "react-router-dom";
import { Link } from "react-router-dom";
import { getTheUserFromStorage } from "./../../services/auth";
import axios from "axios";
import { addToCart } from "../../services/cart";
import LoaderModal from "../LoaderModal";
import VideoPlayer from "./VideoPlayer";
import Reviews from "./Reviews";
import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";
import parse from "html-react-parser";
import { MdArrowDropDownCircle } from "react-icons/md";
import { toast } from "react-toastify";

import ReactPixel from "react-facebook-pixel";

const Details = () => {
  let { slug } = useParams();
  let history = useHistory();

  const { store, setStore } = useContext(storeContext);
  const [loading, setLoading] = useState(false);
  const [alreadyPaid, setAlreadyPaid] = useState(null);
  const [showModules, setShowModules] = useState(null);

  const { data, error } = useSWR(
    `https://farasha.smartiniaclient.com/courses/paid/details/${slug}`,
    fetcher
  );

  useEffect(() => {
    async function fetch() {
      let res = await axios.post(
        `https://farasha.smartiniaclient.com/students/mycourses/${data.id}`,
        {
          user: getTheUserFromStorage().user_id,
        }
      );
      setAlreadyPaid(res.data);
      /* setAlreadyPaid(["aa", "aa"]); */
      return res;
    }
    data && store.isLogged && fetch();
  }, [data]);
  data && console.log(data);
  return (
    <div className="min-h-80 h-full md:space-y-16 width-80-100 md:w-9/12 mx-auto mt-12">
      {data && (
        <h1 className="text-orange font-extrabold text-2xl lg:text-4xl 2xl:text-5xl text-center ">
          {data.title}
        </h1>
      )}
      <div className=" flex flex-col md:flex-row ">
        <div className="x-sm:p-8 2xl:p-20 x-sm:w-full flex-50">
          {data && (
            <img className="rounded-2xl" src={data.picture} alt="blog-pic" />
          )}

          {!store.isLogged && data && (
            <div className="flex flex-row items-center justify-center space-x-4">
              <div className="flex flex-col items-center justify-center space-y-3">
                <h6 className="mt-8 bg-orange text-white x-sm:text-sm sm:text-base lg:text-lg 2xl:text-xl text-center p-2 rounded-xl">
                  <p>السعر في الجزائر</p>
                  <p className="flex flex-row items-center justify-center space-x-2">
                    <span>دج</span>
                    <span> {`${data.price}`}</span>
                  </p>
                </h6>
              </div>
              <div className="flex flex-col items-center justify-center space-y-3">
                <h6 className="mt-8 bg-orange text-white x-sm:text-sm sm:text-base lg:text-lg 2xl:text-xl text-center p-2 rounded-xl">
                  <p>السعر خارج الجزائر</p>
                  <p className="flex flex-row items-center justify-center space-x-2">
                    <span>دولار</span>
                    <span>{`${data.outside_price}`}</span>
                  </p>
                </h6>
              </div>
            </div>
          )}

          <div className="flex justify-center mt-4 space-x-6 2xl:space-x-12">
            {store.isLogged && data && alreadyPaid && alreadyPaid.length === 0 && (
              <div className="flex flex-row items-center justify-center space-x-4">
                <div className="flex flex-col items-center justify-center space-y-3">
                  <h6 className="mt-8 bg-orange text-white x-sm:text-sm sm:text-base lg:text-lg 2xl:text-xl text-center p-2 rounded-xl">
                    <p>السعر في الجزائر</p>
                    <p className="flex flex-row items-center justify-center space-x-2">
                      <span>دج</span>
                      <span> {`${data.price}`}</span>
                    </p>
                  </h6>
                  {!data.is_locked && (
                    <button
                      onClick={async () => {
                        // add to cart
                        setLoading(true);
                        let res = await addToCart({
                          course: data.id,
                          user: getTheUserFromStorage().user_id,
                          is_outside_price: false,
                        });
                        if (res.status === 201) {
                          setLoading(false);
                          ReactPixel.track("AddToCart", {
                            content_ids: [data.id],
                            content_name: data.title,
                            content_type: "course",
                            currency: "DZD",
                            value: data.price,
                          });
                          history.push("/cart");
                        } else if (res.status === 200) {
                          setLoading(false);
                          toast(res.data.Message);
                        }
                      }}
                      className="flex flex-row items-center justify-center space-x-2 bg-light-orange text-blue-night px-8 py-4 rounded-lg font-extrabold x-sm:text-sm sm:text-base lg:text-lg 2xl:text-xl outline-none focus:outline-none">
                      <img src="/images/dz.png" className="h-6 w-6" alt="dz" />
                      <span>أضف إلى السلة</span>
                    </button>
                  )}
                </div>
                <div className="flex flex-col items-center justify-center space-y-3">
                  <h6 className="mt-8 bg-orange text-white x-sm:text-sm sm:text-base lg:text-lg 2xl:text-xl text-center p-2 rounded-xl">
                    <p>السعر خارج الجزائر</p>
                    <p className="flex flex-row items-center justify-center space-x-2">
                      <span>دولار</span>
                      <span>{`${data.outside_price}`}</span>
                    </p>
                  </h6>
                  {!data.is_locked && (
                    <button
                      onClick={async () => {
                        // add to cart
                        setLoading(true);
                        let res = await addToCart({
                          course: data.id,
                          user: getTheUserFromStorage().user_id,
                          is_outside_price: true,
                        });
                        if (res.status === 201) {
                          setLoading(false);
                          ReactPixel.track("AddToCart", {
                            content_ids: [data.id],
                            content_name: data.title,
                            content_type: "course",
                            currency: "USD",
                            value: data.outside_price,
                          });
                          history.push("/cart");
                        } else if (res.status === 200) {
                          setLoading(false);
                          toast(res.data.Message);
                        }
                      }}
                      className="flex flex-row items-center justify-center space-x-2 bg-light-orange text-blue-night px-8 py-4 rounded-lg font-extrabold x-sm:text-sm sm:text-base lg:text-lg 2xl:text-xl outline-none focus:outline-none">
                      <img src="/images/us.png" className="h-6 w-6" alt="dz" />
                      <span>أضف إلى السلة</span>
                    </button>
                  )}
                </div>
              </div>
            )}

            {!store.isLogged && data && !data.is_locked && (
              <div className="w-full flex flex-col items-center justify-center space-y-3">
                <h6 className="mt-8 bg-orange text-white x-sm:text-sm sm:text-base lg:text-lg 2xl:text-xl text-center p-2 rounded-xl">
                  <p>
                    ملاحظة: يرجى للمستخدمين من تونس و المغرب تواصل مع{" "}
                    <span
                      onClick={() => history.push("/our-agents")}
                      className="cursor-pointer text-light-orange underline">
                      وكلائنا
                    </span>
                  </p>
                </h6>
              </div>
            )}

            {/* {store.isLogged && data && alreadyPaid && alreadyPaid.length > 0 && (
              <div className="flex flex-row items-center justify-center space-x-4">
                <div className="flex flex-col items-center justify-center space-y-3">
                  <h6 className="mt-8 bg-orange text-white x-sm:text-sm sm:text-base lg:text-lg 2xl:text-xl text-center p-2 rounded-xl">
                    <p>
                      ملاحظة: يرجى للمستخدمين من تونس و المغرب تواصل مع وكلائنا
                    </p>
                  </h6>
                </div>
              </div>
            )} */}

            {store.isLogged && data && alreadyPaid && alreadyPaid.length > 0 && (
              <Link
                to="/my-courses"
                className="bg-light-orange text-blue-night px-8 py-4 2xl:p-3 rounded-lg font-extrabold x-sm:text-sm md:text-lg lg:text-2xl xl:text-xl">
                إذهب إلى دوراتك
              </Link>
            )}
          </div>

          {store.isLogged &&
            data &&
            !data.is_locked &&
            alreadyPaid &&
            alreadyPaid.length === 0 && (
              <div className="flex flex-row items-center justify-center mt-4">
                <div className="w-full flex flex-col items-center justify-center space-y-3">
                  <h6 className="mt-8 bg-orange text-white x-sm:text-sm sm:text-base lg:text-lg 2xl:text-xl text-center p-2 rounded-xl">
                    <p>
                      ملاحظة: يرجى للمستخدمين من تونس و المغرب تواصل مع{" "}
                      <span
                        onClick={() => history.push("/our-agents")}
                        className="cursor-pointer text-light-orange underline">
                        وكلائنا
                      </span>
                    </p>
                  </h6>
                </div>
              </div>
            )}

          {!store.isLogged && data && !data.is_locked && (
            <div className="flex flex-row items-center justify-center mt-8">
              <Link
                to="/login"
                className="bg-light-orange text-blue-night px-8 py-4 rounded-lg font-extrabold x-sm:text-sm md:text-lg lg:text-xl 2xl:text-xl">
                تسجيل للشراء
              </Link>
            </div>
          )}
        </div>
        <div
          className={`flex-50 flex flex-col space-y-8 2xl:space-y-16 items-center  mb-8 md:mb-0 justify-evenly h-1/2 `}>
          {((data && !store.isLogged) ||
            (data && alreadyPaid && alreadyPaid.length === 0)) && (
              <h4 className=" text-center bg-light-orange  text-blue-night p-1 2xl:p-4 rounded-lg font-extrabold x-sm:text-sm md:text-lg lg:text-2xl 2xl:text-5xl">
                حول البرنامج
              </h4>
            )}
          {data && alreadyPaid && alreadyPaid.length > 0 && (
            <div className=" text-xl text-orange  x-sm:leading-loose md:text-base md:leading-loose  lg:leading-loose  2xl:leading-loose">
              <h1 className="text-right mb-8 text-2xl lg:text-4xl 2xl:text-5xl">
                معلومات حول الدورة
              </h1>

              {parse(data.course_information)}
            </div>
          )}

          {((data && !store.isLogged && data.course_video) ||
            (data &&
              alreadyPaid &&
              alreadyPaid.length === 0 &&
              data.course_video)) && (
              <>
                <h1 className="text-right text-xl font-extrabold text-orange mt-0">
                  : الفيديو التعريفي للدورة
                </h1>
                <VideoPlayer url={data.course_video} type="video" />
              </>
            )}

          {((data && !store.isLogged) ||
            (data && alreadyPaid && alreadyPaid.length === 0)) && (
              <div className=" text-xl x-sm:leading-loose md:text-base md:leading-loose  lg:leading-loose  2xl:leading-loose">
                {parse(data.about_course)}
              </div>
            )}

        </div>
      </div>
      {((data && !store.isLogged) ||
        (data && alreadyPaid && alreadyPaid.length === 0)) && (
          <>
            <div className="h-full flex flex-col space-y-6 2xl:space-y-12  font-extrabold leading-loose tracking-widest ">
              <h1 className="text-right text-orange text-2xl lg:text-4xl 2xl:text-5xl">
                وصف الدورة
              </h1>

              <div className="text-base x-sm:text-xs x-sm:leading-loose md:text-base md:leading-loose  lg:leading-loose  2xl:leading-loose ">
                {parse(data.description)}
              </div>
            </div>

            <div className="h-full flex flex-col space-y-6 2xl:space-y-12  font-extrabold leading-loose tracking-widest ">
              <h1 className="text-right text-orange  text-2xl lg:text-4xl 2xl:text-5xl">
                على ماذا ستحصل بعد الشراء
              </h1>

              <div className="text-base x-sm:text-xs x-sm:leading-loose md:text-base md:leading-loose  lg:leading-loose  2xl:leading-loose ">
                {parse(data.what_you_get)}
              </div>
            </div>
          </>
        )}
      {data && alreadyPaid && alreadyPaid.length > 0 && (
        <div className=" width-80-100 md:w-1/2 mx-auto text-reghda-black ">
          <h1 className="text-center text-orange text-2xl lg:text-4xl 2xl:text-5xl">
            محتوي الدورة
          </h1>
          <div className="text-right my-8">
            {data.chapters.map((chapter) => (
              <>
                <div
                  key={chapter.id}
                  className="flex justify-end bg-orange text-white p-4 rounded-xl text-xl lg:text-2xl 2xl:text-3xl mb-2 flex justify-between items-center">
                  <MdArrowDropDownCircle
                    className="cursor-pointer"
                    onClick={() =>
                      showModules === chapter.id
                        ? setShowModules(null)
                        : setShowModules(chapter.id)
                    }
                  />
                  <h1 className="font-extrabold">{chapter.title}</h1>
                </div>

                {showModules === chapter.id &&
                  chapter.modules.map((module) => (
                    <div
                      key={module.id}
                      className="ml-8 my-2 p-4 text-gray-500 rounded-xl text-base lg:text-xl 2xl:text-2xl border-2 border-reghda-pink font-bold bg-white hover:bg-red-200 hover:text-white transition duration-500 ease-in-out">
                      {module.title}
                    </div>
                  ))}
              </>
            ))}
          </div>
        </div>
      )}
      {data && (
        <Reviews
          setLoading={setLoading}
          isLogged={store.isLogged}
          alreadyPaid={alreadyPaid}
          courseId={data.id}
        />
      )}
      <LoaderModal modalIsOpen={loading}></LoaderModal>
      <LoaderModal modalIsOpen={!data}></LoaderModal>
    </div>
  );
};

export default Details;
