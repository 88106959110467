import { PayPalButton } from "react-paypal-button-v2";
import { enroll } from "./../../../services/enrollment";
import { useHistory } from "react-router-dom";
import { getTheUserFromStorage } from "./../../../services/auth";
import { clearCart } from "../../../services/cart";
import { createOrder } from "../../../services/orders";
import { toast } from "react-toastify";
import ReactPixel from "react-facebook-pixel";


const PayButton = ({ shippmentDetail, total, courses, setLoading }) => {
  const history = useHistory();
  return (
    <>
      <div className="flex flex-row items-center justify-between py-4">
        <div className="flex flex-row space-x-4 items-center justify-center">
          <img
            src="/images/footer/paypal.png"
            className="x-sm:h-3 sm:h-5"
            alt="icon"
          />
        </div>
        <p className="text-reghda-black text-right x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl font-bold">
          الدفع عبر
        </p>
      </div>
      <PayPalButton
        style={{
          color: "blue",
          shape: "rect",
          size: "responsive",
          // label: "checkout",
        }}
        options={{
          // clientId: "sb",
          clientId:
            "AUntJ9-LNeuVcjoam6VbwWHfi-IEwLv-hVquDURxLzktdHF1Sf_Q2a4Xw2OvMbtYUfIHpqSbfLwpENs6",
          currency: "USD",
          disableFunding: "card",
          // disableFunding: "credit,card",
        }}
        amount={total}
        // amount={Math.round(total / 162)}
        shippingPreference="NO_SHIPPING" // default is "GET_FROM_FILE"
        onSuccess={async (details, data) => {
          setLoading(true);
          //Call your server to save the transaction
          if (details.status === "COMPLETED") {
            // create order
            await createOrder({
              user: getTheUserFromStorage().user_id,
              payment_method: "Paypal",
              given_name: shippmentDetail.firstname,
              family_name: shippmentDetail.lastname,
              country: shippmentDetail.country,
              address: shippmentDetail.addressOne,
              second_address: shippmentDetail.addressTwo,
              phone_number: shippmentDetail.phone,
            });
            // enroll
            let res = await enroll({
              course: courses.map((course) => course.id),
              user: getTheUserFromStorage().user_id,
            });
            if (res && res.status === 201) {
              setLoading(false);
              toast("العملية تمت بنجاح 🎉");
              ReactPixel.track("Purchase", {
                content_ids: [
                  ...courses.map((course) => course.id),
                ],
                currency: "USD",
                value: total,
              });
              await clearCart(getTheUserFromStorage().user_id);
              history.push("/my-courses");
            } else {
              setLoading(false);
              toast("العملية لم تتم بنجاح ❌");
            }
          }
          setLoading(false);
        }}
        onError={(err) => {
          toast("العملية لم تتم بنجاح ❌");
          console.log("👀", err);
        }}
        catchError={(err) => {
          toast("العملية لم تتم بنجاح ❌");
          console.log("❌", err);
        }}
      />
    </>
  );
};

export default PayButton;

// c id AUntJ9-LNeuVcjoam6VbwWHfi-IEwLv-hVquDURxLzktdHF1Sf_Q2a4Xw2OvMbtYUfIHpqSbfLwpENs6
// secret EBfFXBcBeuntKa_25F1ixA139K-sdpji4WDtmymvU3on8K9xM_aibhKhj0D9VJdugcTIqrAdbTTJDFBB

//sandbox
// sb-lg7yq5468518@personal.example.com
// GN.j7)o.
