import React, { useState, useEffect } from "react";
import { IconContext } from "react-icons";
import { AiOutlineClose } from "react-icons/ai";
import useSWR, { mutate } from "swr";
import { fetcher } from "./../../../services/fetcher";
import { deleteFromCart, clearCart } from "../../../services/cart";
import { getTheUserFromStorage } from "./../../../services/auth";
import { Link, useHistory } from "react-router-dom";
import LoaderModal from "../../LoaderModal";

const Cart = () => {
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const [agreeConditions, setAgreeConditions] = useState(false);
  const [agreeTerms, setAgreeTerms] = useState(false);
  const [total, setTotal] = useState(null);
  const [courseIds, setCourseIds] = useState([]);

  const { data, error } = useSWR(
    `https://farasha.smartiniaclient.com/cart/cart-details/${
      getTheUserFromStorage().user_id
    }`,
    fetcher
  );

  useEffect(() => {
    let courseSum = 0;

    data &&
      data.cart.courses.length > 0 &&
      data.cart.courses.map((course, i) => (courseSum += course.price));

    setTotal(courseSum);

    let courseArr = [];
    data &&
      data.cart.courses.length > 0 &&
      data.cart.courses.map((course, i) => courseArr.push(course));

    setCourseIds(courseArr);
  }, [data]);

  return (
    <div className="min-h-80 flex flex-row items-center justify-center pb-16">
      {(!data || data.cart.courses.length < 1) && (
        <div className="text-center flex flex-row items-center justify-center pt-6 x-sm:text-lg sm:text-2xl lg:text-4xl 2xl:text-6xl font-bold">
          <h1 className="text-orange">السلة فارغة</h1>
        </div>
      )}

      {data && data.cart.courses.length > 0 && (
        <div className="h-full w-3/4 space-y-6">
          <div className="text-center pt-6 x-sm:text-lg sm:text-2xl lg:text-4xl 2xl:text-6xl font-bold">
            <h1 className="text-orange">سلة المشتريات</h1>
          </div>
          <div className="bg-gray-50 rounded-2xl shadow-2xl">
            <table className="border-collapse w-full text-center md:text-lg 2xl:text-3xl text-blue-night">
              <tr>
                <th className="border-2 border-solid bg-gray-100 border-gray-100 p-4">
                  حذف
                </th>
                <th className="border-2 border-solid bg-gray-100 border-gray-100 p-4">
                  السعر
                </th>
                <th className="border-2 border-solid bg-gray-100 border-gray-100 p-4">
                  المنتج
                </th>
              </tr>
              {data &&
                data.cart.courses.length > 0 &&
                data.cart.courses.map((course, i) => {
                  return (
                    <tr key={i}>
                      <td
                        onClick={async () => {
                          setLoading(true);
                          let res = await deleteFromCart({
                            course: course.id,
                            user: getTheUserFromStorage().user_id,
                          });
                          if (res.status === 200) {
                            mutate(
                              `https://farasha.smartiniaclient.com/cart/cart-details/${
                                getTheUserFromStorage().user_id
                              }`
                            );
                            setLoading(false);
                          } else {
                            setLoading(false);
                            alert("something wrong, login and retry");
                          }
                        }}
                        className="cursor-pointer text-center flex flex-row items-center justify-center p-2">
                        <IconContext.Provider
                          value={{ size: "1em", style: { color: "#e53e3e" } }}>
                          <AiOutlineClose></AiOutlineClose>
                        </IconContext.Provider>
                      </td>
                      <td className="border-gray-200 p-2 text-gray-600">
                        {data.type === "inside"
                          ? `دج ${course.price}`
                          : `دولار ${course.price}`}
                      </td>
                      <td className="border-gray-200 p-2 text-gray-600">
                        {course.title}
                      </td>
                    </tr>
                  );
                })}
            </table>
          </div>
          <div className="flex flex-col items-end justify-center">
            <div className="space-x-3 cursor-pointer text-gray-600">
              <label for="vehicle1">
                أنا أوافق على{" "}
                <Link to="/rules" className="text-orange underline">
                  الشروط و الأحكام
                </Link>
              </label>
              <input
                type="checkbox"
                id="conditions"
                onChange={() => {
                  setAgreeConditions(!agreeConditions);
                }}
              />
            </div>
            <div className="space-x-3 cursor-pointer text-gray-600">
              <label for="vehicle2">
                أنا أوافق على{" "}
                <Link to="/return" className="text-orange underline">
                  سياسة الإرجاع
                </Link>
              </label>
              <input
                type="checkbox"
                id="terms"
                onChange={() => {
                  setAgreeTerms(!agreeTerms);
                }}
              />
            </div>
          </div>
          <div className="flex flex-col items-center justify-center space-y-6">
            <button
              onClick={() => {
                if (agreeConditions && agreeTerms) {
                  history.push("/check-out", {
                    data: {
                      total: total,
                      courses: courseIds,
                      cartId: data.id,
                      type: data.type,
                    },
                  });
                } else {
                  alert("يجب عليك الموافقة على الشروط");
                }
              }}
              className="w-full bg-orange text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-16 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-2xl 2xl:text-5xl outline-none focus:outline-none">
              مواصلة
            </button>
            <button
              onClick={async () => {
                setLoading(true);
                const res = await clearCart(getTheUserFromStorage().user_id);

                if (res.status === 200) {
                  mutate(
                    `https://farasha.smartiniaclient.com/cart/cart-details/${
                      getTheUserFromStorage().user_id
                    }`
                  );
                  setLoading(false);
                } else {
                  setLoading(false);
                  alert("something wrong, retry");
                }
              }}
              className="w-full bg-orange text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-16 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-2xl 2xl:text-5xl outline-none focus:outline-none">
              إفراغ السلة
            </button>
          </div>
        </div>
      )}
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default Cart;
