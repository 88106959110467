import React, { useState } from "react";
import { Link, useHistory } from "react-router-dom";
import { IconContext } from "react-icons";
import { FaUserAlt } from "react-icons/fa";
import { AiFillEye, AiFillStar } from "react-icons/ai";
import { flex } from "tailwindcss/defaultTheme";
import  "./styles.css";
const ArticleCard = ({ article }) => {
  const history = useHistory();

  function truncate(str, no_words) {
    return str.split(" ").splice(0, no_words).join(" ");
  }

  return (
    <div
      onClick={() => history.push(`/articles/${article.slug}`)}
      className="h-full w-full text-blue-night cursor-pointer x-sm:p-1 sm:p-4 space-y-2 rounded-2xl"
    >
      <div className="h-full w-full bg-light-orange flex flex-col items-center space-y-4 bg-white rounded-2xl shadow-2xl text-reghda-blue">
        {article.image && (
          <img
            className="rounded-2xl h-full w-full object-cover"
            src={article.image}
            alt="blog-pic"
          />
        )}
        <h6 className=" article_title_cstm w-full rounded-xl bg-orange px-10 x-sm:text-lg sm:text-xl lg:text-2xl 2xl:text-4xl text-center">
          <span>{article.title}</span>
        </h6>
        <h6 className="  px-6 x-sm:text-xs sm:text-sm lg:text-lg 2xl:text-2xl text-right article_desc_cstm" >
          {truncate(article.description, 20)}
        </h6>
        <div className="px-6 pb-6 w-full flex flex-row items-center justify-between x-sm:text-xs 2xl:text-base">
          <div className="x-sm:text-xxs sm:text-xs lg:text-sm 2xl:text-base text-white">
            <div className="rounded-2xl bg-orange px-4 py-1 flex flex-row items-center justify-center space-x-2">
              إقراء المزيد
            </div>
          </div>
          <div className="x-sm:text-xs sm:text-sm lg:text-base 2xl:text-lg">
            <IconContext.Provider
              value={{ size: "1.8em", style: { color: "#d29e83" } }}
            >
              <AiFillEye></AiFillEye>
            </IconContext.Provider>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
