import Axios from "axios";

// Axios.defaults.headers.common = {
//   Authorization: `Bearer ${localStorage.getItem("token")}`,
// };

export async function enroll(data) {
  try {
    const response = await Axios.post(
      `https://farasha.smartiniaclient.com/students/enroll`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function packEnroll(data) {
  try {
    const response = await Axios.post(
      `https://farasha.smartiniaclient.com/students/packs/enroll`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
