import React, { useState } from "react";
import useSWR, { mutate, trigger } from "swr";
import { makeStyles } from "@material-ui/core/styles";
import { fetcher } from "./../../services/fetcher";
import Rating from "@material-ui/lab/Rating";
import {
  addComment,
  deleteComment,
  editComment,
} from "../../services/courseComments";

import { getTheUserFromStorage } from "./../../services/auth";

const Reviews = ({ courseId, isLogged, alreadyPaid, setLoading }) => {
  const [detail, setDetail] = useState("");
  const [openEditComment, setOpenEditComment] = useState(false);
  const [rating, setRating] = useState(0);

  const { data, error } = useSWR(
    `https://farasha.smartiniaclient.com/reviews/courses/?course=${courseId}`,
    fetcher
  );

  const { data: isCommented } = useSWR(
    isLogged
      ? `https://farasha.smartiniaclient.com/reviews/has-permission?user=${
          getTheUserFromStorage().user_id
        }&course=${courseId}`
      : null,
    fetcher
  );

  return (
    <div className="py-6  w-full md:w-10/12 mx-auto">
      <div className="text-right text-base text-orange x-sm:leading-loose md:leading-loose  lg:leading-loose  2xl:leading-loose w-11/12 mx-auto">
        <h1 className="text-orange text-center text-2xl lg:text-4xl 2xl:text-5xl  mb-4">
          آراء المشاهدين السابقين
        </h1>

        {data && data.length === 0 && (
          <div className="h-36 flex items-center justify-center">
            <p className="text-orange text-base sm:text-2xl md:text-4xl">
              لا يوجد آراء
            </p>
          </div>
        )}
        {data &&
          data.map((item) => (
            <div className="border-bottom-grey" key={item.id}>
              <div className="py-8 flex flex-col-reverse items-end justify-end space-x-3">
                {isLogged && item.user.id === getTheUserFromStorage().user_id && (
                  <div className="flex flex-30 items-center mt-8">
                    <button
                      className="mr-4 bg-gradient-to-r from-purple-900 to-pink-400 text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-8 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl outline-none focus:outline-none min-w-100"
                      onClick={async () => {
                        setLoading(true);
                        const res = await deleteComment(item.id);
                        mutate(
                          `https://farasha.smartiniaclient.com/reviews/courses/?course=${courseId}`,
                          data.filter((book) => book.id !== item.id),
                          false
                        );
                        trigger(
                          `https://farasha.smartiniaclient.com/reviews/has-permission?user=${
                            getTheUserFromStorage().user_id
                          }&course=${courseId}`
                        );
                        setDetail("");
                        setRating(0);

                        setLoading(false);
                      }}>
                      حدف
                    </button>
                    <button
                      onClick={async () => {
                        if (!openEditComment) {
                          setDetail(item.comment);
                          setRating(item.rating);
                          setOpenEditComment(!openEditComment);
                        } else {
                          setLoading(true);
                          const res = await editComment({
                            commentId: item.id,
                            detail: detail,
                            rating: rating,
                          });
                          mutate(
                            `https://farasha.smartiniaclient.com/reviews/courses/?course=${courseId}`
                          );
                          setRating(0);

                          setDetail("0");
                          setOpenEditComment(false);
                          setLoading(false);
                        }
                      }}
                      className="bg-gradient-to-r from-purple-900 to-pink-400 text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-8 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl outline-none focus:outline-none min-w-100">
                      {openEditComment ? "تأكيد" : "تعديل"}
                    </button>
                  </div>
                )}
                <div className="w-full ">
                  {!openEditComment && (
                    <p className="x-sm:text-base  lg:text-2xl mt-4">
                      {item.comment}
                    </p>
                  )}
                  {isLogged &&
                    item.user.id === getTheUserFromStorage().user_id &&
                    openEditComment && (
                      <div className="space-y-4 text-orange">
                        <div className="flex items-center justify-end space-x-2 space-y-4">
                          <Rating
                            name="simple-controlled"
                            value={rating}
                            onChange={(event, newValue) => {
                              setRating(newValue);
                            }}
                          />
                          <span className="text-xl text-orange">
                            : تقييم الدورة
                          </span>
                        </div>

                        <div className="w-full  mb-4">
                          <span className="text-xl text-orange">
                            : تفاصيل المراجعة
                          </span>
                          <textarea
                            onChange={(event) => setDetail(event.target.value)}
                            value={detail}
                            placeholder="تعليق"
                            rows="3"
                            id="text"
                            type="text"
                            class="w-full border-2 border-gray-200 bg-white text-gray-400 text-right text-2xl p-3 2xl:p-9 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
                          />
                        </div>
                      </div>
                    )}
                </div>

                <div className=" flex flex-col items-center justify-center space-y-2 space-x-4">
                  <div className="flex items-center space-x-4">
                    <div>
                      <p
                        style={{ margin: 0 }}
                        className="text-center   font-extrabold x-sm:text-sm sm:text-base ">{`${item.user.given_name} ${item.user.family_name}`}</p>
                      <p className="x-sm:text-sm sm:text-sm">
                        {item.created_at.slice(0, 10)}
                      </p>
                    </div>
                    <img
                      style={{ marginTop: 0 }}
                      className="rounded-full x-sm:w-8 x-sm:h-8 sm:w-12 sm:h-12 md:w-16 md:h-16 2xl:w-16 2xl:h-16 picfit"
                      alt="user"
                      src={
                        item.user.image
                          ? item.user.image
                          : "/images/admin/default-user.png"
                      }
                    />
                  </div>
                  {!openEditComment && (
                    <div className="flex justify-end space-x-4 ">
                      <div className="space-x-4 flex items-center ">
                        <Rating readOnly value={item.rating} />
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          ))}
      </div>

      {isLogged && alreadyPaid && alreadyPaid.length > 0 && isCommented && (
        <div className="pt-4 pb-20 text-right text-base text-gray-600   x-sm:leading-loose md:leading-loose  lg:leading-loose  2xl:leading-loose w-11/12 mx-auto">
          <div className="py-8 flex flex-row items-center justify-items-center space-x-3">
            <div className="w-1/5">
              <div className="flex flex-row items-center justify-center">
                <button
                  onClick={async () => {
                    if (detail.length < 1) {
                      return;
                    }
                    setLoading(true);
                    let res = await addComment({
                      user: getTheUserFromStorage().user_id,
                      course: courseId,
                      rating: rating,
                      comment: detail,
                    });
                    if (res && res.status === 201) {
                      mutate(
                        `https://farasha.smartiniaclient.com/reviews/courses/?course=${courseId}`
                      );
                    } else {
                      alert("something wrong!");
                    }
                    setDetail("");

                    setRating(0);
                    trigger(
                      `https://farasha.smartiniaclient.com/reviews/has-permission?user=${
                        getTheUserFromStorage().user_id
                      }&course=${courseId}`
                    );
                    setOpenEditComment(false);
                    setLoading(false);
                  }}
                  className="bg-gradient-to-r from-purple-900 to-pink-400 text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-8 2xl:py-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-2xl outline-none focus:outline-none min-w-100">
                  أضف
                </button>
              </div>
            </div>
            <div className="space-y-4 w-4/5">
              <div className="flex items-center justify-end space-x-2 text-orange ">
                <Rating
                  name="simple-controlled"
                  value={rating}
                  onChange={(event, newValue) => {
                    setRating(newValue);
                  }}
                />
                <span className="text-xl">: تقييم الدورة</span>
              </div>

              <div className="w-full  mb-4">
                <span className="text-xl text-orange">: تفاصيل المراجعة</span>
                <textarea
                  onChange={(event) => setDetail(event.target.value)}
                  value={detail}
                  placeholder="تعليق"
                  rows="3"
                  id="text"
                  type="text"
                  class="w-full border-2 border-gray-200 bg-white text-right text-2xl p-3 2xl:p-9 pr-6 rounded-2xl outline-none focus:outline-none focus:border-pink-300"
                />
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default Reviews;
