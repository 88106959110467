import React from "react";
import useSwr from "swr";
import LoaderModal from "../LoaderModal";
import { getTheUserFromStorage } from "../../services/auth";
import { fetcher } from "../../services/fetcher";

import {
  Table,
  TableRow,
  TableHead,
  TableContainer,
  TableCell,
  TableBody,
  Paper,
} from "@material-ui/core";

const Orders = () => {
  const userId = getTheUserFromStorage().user_id;
  const { data, error } = useSwr(
    `https://farasha.smartiniaclient.com/orders/my-orders/?user=${userId}`,
    fetcher
  );

  console.log(data, "🎉");

  return (
    <>
      {data && data.length > 0 ? (
        <>
          <div className="text-blue-night min-h-80 w-full xl:w-9/12 mx-auto mt-16 p-4 hidden md:block">
            <TableContainer component={Paper} className="rtl">
              <Table aria-label="simple table">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">
                      <p className="text-xl  font-semibold text-blue-night">
                        العناصر
                      </p>
                    </TableCell>
                    <TableCell align="center">
                      <p className="text-xl  font-semibold text-blue-night">
                        التكلفة الإجمالية
                      </p>
                    </TableCell>
                    <TableCell align="center">
                      <p className="text-xl  font-semibold text-blue-night">
                        طريقة الدفع
                      </p>
                    </TableCell>
                    <TableCell align="center">
                      <p className="text-xl  font-semibold text-blue-night">
                        تاريخ الشراء
                      </p>
                    </TableCell>
                    <TableCell align="center">
                      <p className="text-xl  font-semibold text-blue-night">
                        حالة الطلب
                      </p>
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data &&
                    data.map((item) => (
                      <TableRow>
                        <TableCell align="center">
                          {item.items.length === 0 ? (
                            <p className="text-base text-blue-night">
                              لا توجد عناصر بهذا الطلب
                            </p>
                          ) : (
                            <ul>
                              {item.items.map((product) => (
                                <li
                                  key={product.id}
                                  className="text-base text-blue-night">
                                  {product.product_title}
                                </li>
                              ))}
                            </ul>
                          )}
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-base text-blue-night space-x-2">
                            <span>{item.total_cost}</span> <span>دج</span>
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-base text-blue-night">
                            {item.payment_method}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-base text-blue-night">
                            {item.created_at.slice(0, 10)}
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p
                            className={`text-base  text-blue-night${
                              item.status === "مرفوض" && "text-red-500"
                            } ${item.status === "مقبول" && "text-green-500"}`}>
                            {item.status}
                          </p>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          <div className="min-h-80 w-full xl:w-8/12 mx-auto mt-16 p-4 block md:hidden">
            {data &&
              data.map((item) => (
                <TableContainer
                  key={item.id}
                  component={Paper}
                  className="rtl mb-4">
                  <Table>
                    <TableBody>
                      <TableRow>
                        <TableCell align="center" variant="head">
                          <p className="text-base  font-semibold text-blue-night">
                            العناصر
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          {item.items.length === 0 ? (
                            <p className="text-base ">
                              لا توجد عناصر بهذا الطلب
                            </p>
                          ) : (
                            <ul>
                              {item.items.map((product) => (
                                <li
                                  key={product.id}
                                  className="text-xl text-blue-night">
                                  {product.product_title}
                                </li>
                              ))}
                            </ul>
                          )}
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" variant="head">
                          <p className="text-base  font-semibold">
                            التكلفة الإجمالية
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-base text-blue-night space-x-2">
                            <span>{item.total_cost}</span> <span>دج</span>
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" variant="head">
                          <p className="text-base text-blue-night font-semibold">
                            طريقة الدفع
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-base text-blue-night">
                            {item.payment_method}
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" variant="head">
                          <p className="text-base text-blue-night font-semibold">
                            تاريخ الشراء
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p className="text-base text-blue-night">
                            {item.created_at.slice(0, 10)}
                          </p>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell align="center" variant="head">
                          <p className="text-base text-blue-night font-semibold">
                            حالة الطلب
                          </p>
                        </TableCell>
                        <TableCell align="center">
                          <p
                            className={`text-xl text-blue-night ${
                              item.status === "مرفوض" && "text-red-500"
                            } ${item.status === "مقبول" && "text-green-500"}`}>
                            {item.status}
                          </p>
                        </TableCell>
                      </TableRow>
                    </TableBody>
                  </Table>
                </TableContainer>
              ))}
          </div>
        </>
      ) : (
        <div className="min-h-80 w-full flex flex-row justify-center items-center x-sm:text-xl md:text-6xl font-extrabold text-orange">
          <p>الطلبات فارغة</p>
        </div>
      )}
      <LoaderModal modalIsOpen={!data}></LoaderModal>
    </>
  );
};

export default Orders;
