import React from "react";
import Header from "../Header";
import Overview from "./content/Overview";
import Programs from "./content/Programs";
import FreeContent from "./content/FreeContent";
import StoreOverview from "./content/StoreOverview";
import ArticlesOverview from "./content/ArticlesOverview";
import PaidContent from "./content/PaidContent";
import Sayings from "./content/Sayings";
import AboutUs from "./content/AboutUs";
import Footer from "../Footer";

const Page = () => {
  return (
    <>
      <Header />
      <Overview></Overview>
      <div className="home w-full xl:w-9/12 mx-auto px-4">
        <Programs></Programs>
        <FreeContent></FreeContent>
        {
          //<StoreOverview></StoreOverview>
        }
        <ArticlesOverview></ArticlesOverview>
        <PaidContent></PaidContent>
        <Sayings></Sayings>
        <AboutUs></AboutUs>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Page;
