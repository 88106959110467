import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import LoaderModal from "../../LoaderModal";
import useSWR from "swr";
import { fetcher } from "./../../../services/fetcher";
import { resetPassword } from "../../../services/resetPassword";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { useEffect } from "react";

const ResetPassword = ({ params }) => {
  const [changeSuccess, setChangeSuccess] = useState(false);

  const userId = params.userId;
  const token = params.token;
  const { data, error } = useSWR(
    !changeSuccess ?
      `https://farasha.smartiniaclient.com/users/reset-password/${userId}/${token}`
      : null,
    fetcher,
    {
      revalidateIfStale: false,
      revalidateOnFocus: false,
      revalidateOnReconnect: false
    }
  );
   console.log(error);
  const validationSchema = Yup.object({
    password: Yup.string()
      .required("هذا الحقل مطلوب")
      .min(8, "كلمة المرور قصيرة جدًا - يجب ألا تقل عن 8 أحرف."),
    confirmPassword: Yup.string()
      .required("هذا الحقل مطلوب")
      .oneOf([Yup.ref("password"), null], "يجب ان تتطابق كلمات المرور"),
  });

  return (
    <div className="flex flex-row h-screen">
      <div className="hidden md:block bg-purple-800 h-screen w-1/2 overview-sl"></div>
      <div className="flex justify-center items-start w-full md:w-1/2  md:items-center">
        <div
          className="flex flex-col justify-center items-center p-4 rounded-3xl"
          style={{
            width: "40vw",
            minWidth: 280,
          }}>
          <img
            src="/images/logo.png"
            style={{ maxWidth: 100 }}
            className="pb-8"
            alt="logo"
          />

          {error ? (
            <p className="text-red-400">حدث خطأ</p>
          ) : (
            <>
              {changeSuccess ? (
                <>
                  <p className="text-xl text-orange text-center mb-8">
                    لقد تم تحديث كلمة السر الخاصة بك بنجاح
                  </p>
                  <Link
                    to="/"
                    className="bg-orange text-white text-center w-3/4 x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg my-8">
                    اذهب إلى الصفحة الرئيسية
                  </Link>
                </>
              ) : (
                <Formik
                  initialValues={{
                    password: "",
                    confirmPassword: "",
                  }}
                  validationSchema={validationSchema}
                  onSubmit={async (values, { setSubmitting }) => {
                    const { password } = values;
                    const res = await resetPassword(userId, token, password);
                    if (res && res.data && res.status === 200) {
                      setChangeSuccess(true);
                    } else {
                      toast("حدث خطأ ، حاول مرة أخرى ");
                    }
                    setSubmitting(false);
                  }}>
                  <Form className="w-full">
                    <Field
                      className="bg-white w-full text-orange placeholder-gray-300 text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300 "
                      placeholder="كلمة السر الجديدة"
                      name="password"
                      type="password"
                    />
                    <div className="text-red-400 border-red-400 mb-4 md:text-sm xl:text-base text-center">
                      <ErrorMessage name="password" />
                    </div>
                    <Field
                      className="bg-white w-full text-orange placeholder-gray-300 mb-4 text-right x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl outline-none focus:outline-none  focus:border-pink-300"
                      placeholder=" تاكيد كلمة السر الجديدة"
                      name="confirmPassword"
                      type="password"
                    />
                    <div className="text-red-400 border-red-400 mb-4 md:text-sm xl:text-base text-center">
                      <ErrorMessage name="confirmPassword" />
                    </div>
                    <button
                      className="bg-orange text-white text-center w-full x-sm:p-1 md:p-2 2xl:p-4 pr-6 rounded-2xl shadow-lg  focus:outline-none	active:outline-none"
                      type="submit">
                      تغيير كلمة السر
                    </button>
                  </Form>
                </Formik>
              )}
            </>
          )}
        </div>
        <LoaderModal modalIsOpen={!data && !error && !changeSuccess }></LoaderModal>
      </div>
    </div>
  );
};

export default ResetPassword;
