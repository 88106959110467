import React, { useState } from "react";

import { Link, useHistory } from "react-router-dom";
import { IconContext } from "react-icons";
import { FaUserAlt } from "react-icons/fa";
import { AiFillEye, AiFillStar } from "react-icons/ai";
import { TiShoppingCart } from "react-icons/ti";

const ArticleCard = ({ course }) => {
  const history = useHistory();

  return (
    <div
      onClick={() => {
        if (course.type === "free") {
          history.push(`/course/${course.slug}`);
        } else if (course.type === "paid") {
          history.push(`/paid-courses/course-detail/${course.slug}`);
        }
      }}
      className="w-full h-full text-blue-night cursor-pointer x-sm:p-1 sm:p-4 space-y-2 rounded-2xl"
    >
      <div className="w-full h-full flex flex-col items-center space-y-4 bg-white rounded-2xl shadow-2xl text-reghda-blue">
        {course.picture && (
          <img
            className="rounded-2xl w-full h-full object-cover"
            src={course.picture}
            alt="blog-pic"
          />
        )}
        <h6 className="x-sm:text-base sm:text-lg lg:text-xl 2xl:text-2xl text-center">
          {course.title}
        </h6>
        <div className="w-full flex flex-row items-center justify-between px-6 pb-6 x-sm:text-xxs sm:text-xs md:text-sm 2xl:text-lg">
          <div> {course.type === "paid" ? `${course.price}DZA` : null} </div>
          <div className="text-white flex flex-row items-center justify-end space-x-4 ">
            <IconContext.Provider
              value={{ size: "2em", style: { color: "#d29e83" } }}
            >
              <AiFillEye></AiFillEye>
            </IconContext.Provider>
            {course.type === "paid" && (
              <IconContext.Provider
                value={{ size: "2em", style: { color: "#d29e83" } }}
              >
                <TiShoppingCart></TiShoppingCart>
              </IconContext.Provider>
            )}
            <div className="rounded-2xl bg-orange px-4 py-1 flex flex-row items-center justify-center space-x-2">
              <IconContext.Provider
                value={{ size: "1.2em", style: { color: "#fff" } }}
              >
                <AiFillStar></AiFillStar>
              </IconContext.Provider>
              <span>{course.total_rating}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ArticleCard;
