import React from "react";
import Header from "./../Header";
import Footer from "./../Footer";
import Details from "./Details";

const CourseDetailPage = () => {
  return (
    <div>
      <Header></Header>
      <Details></Details>
      <Footer></Footer>
    </div>
  );
};

export default CourseDetailPage;
