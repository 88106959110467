import Axios from "axios";

export async function createOrder(data) {
  try {
    const response = await Axios.post(
      `https://farasha.smartiniaclient.com/orders/new`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
