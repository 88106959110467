import React, { useEffect, useState } from "react";
import Header from "./../Header";
import Footer from "./../Footer";
import LoaderModal from "../LoaderModal";
import VideoPlayer from "./VideoPlayer";
import { useLocation, useHistory, useParams } from "react-router-dom";
import ChapterItems from "./ChapterItems";
import Collapsible from "react-collapsible";
import { IconContext } from "react-icons";
import { AiOutlineArrowDown, AiOutlineArrowRight } from "react-icons/ai";
import Reviews from "./Reviews";

import useSWR, { mutate } from "swr";
import { fetcher } from "./../../services/fetcher";

const CourseStudyPage = () => {
  const { slug } = useParams();
  let location = useLocation();

  const [loading, setLoading] = useState(false);

  const { data, error } = useSWR(
    `https://farasha.smartiniaclient.com/courses/all/details/${slug}`,
    fetcher
  );
  data && console.log(data);
  const [totalState, SetTotalState] = useState({
    chapters: [],
    // numberOfChapters: 3,
  });

  const [currentUrl, setCurrentUrl] = useState("");
  const [currentAttachment, setCurrentAttachment] = useState({
    files: [],
    images: [],
    audios: [],
  });
  const [currentType, setCurrentType] = useState("video");

  const [currentChapter, setCurrentChapter] = useState(null);
  const [currentModule, setCurrentModule] = useState(null);

  useEffect(() => {
    data &&
      SetTotalState({
        chapters: data.chapters.map((chapter, i) => {
          return { ...chapter, numberOfModules: chapter.modules.length };
        }),
        numberOfChapters: data.chapters.length,
      });
  }, [data]);

  // const initFirst = () => {
  //   let FirstChapter = totalState.chapters.filter((chapter, i) => (i = 0))[0];
  //   let FirstModule = FirstChapter.modules.filter((module, i) => (i = 0))[0];
  //   setupCurrent(FirstChapter.id, FirstModule.id);
  //   setCurrentChapter(FirstModule.id);
  //   setCurrentModule(FirstModule.id);
  // };

  const setupCurrent = (chapterId, moduleId) => {
    if (chapterId && moduleId) {
      let thisChapter = totalState.chapters.filter(
        (chapter) => chapter.id === chapterId
      )[0];
      let thisModule = thisChapter.modules.filter(
        (module) => module.id === moduleId
      )[0];

      if (thisModule.videos.length > 0) {
        setCurrentType("video");
        thisModule.videos[0].url === ""
          ? setCurrentUrl(thisModule.videos[0].video_content)
          : setCurrentUrl(thisModule.videos[0].url);
      }

      setCurrentAttachment({
        files: [...thisModule.files],
        images: [...thisModule.images],
        audios: [...thisModule.audios],
      });

      if (thisModule.videos.length === 0 && thisModule.audios.length > 0) {
        setCurrentType("audio");
        setCurrentUrl(thisModule.audios[0].url);
      }
    }
  };

  const next = () => {
    let nextModuleIdx;
    let nextChapterIdx;

    let thisChapter = totalState.chapters.filter((chapter, i) => {
      if (chapter.id === currentChapter) {
        nextChapterIdx = i + 1;
        return chapter;
      }
    })[0];

    let thisModule = thisChapter.modules.filter((module, i) => {
      if (module.id === currentModule) {
        nextModuleIdx = i + 1;
        return module;
      }
    })[0];

    if (nextModuleIdx < thisChapter.numberOfModules) {
      let nextModule = thisChapter.modules.filter(
        (module, i) => i === nextModuleIdx
      )[0];
      setupCurrent(thisChapter.id, nextModule.id);
      setCurrentChapter(thisChapter.id);
      setCurrentModule(nextModule.id);
    } else {
      if (nextChapterIdx < totalState.numberOfChapters) {
        let nextChapter = totalState.chapters.filter(
          (chapter, i) => i === nextChapterIdx
        )[0];
        let firstModule = nextChapter.modules.filter((module, i) => i === 0)[0];
        setupCurrent(nextChapter.id, firstModule.id);
        setCurrentChapter(nextChapter.id);
        setCurrentModule(firstModule.id);
      }
    }
  };

  const previous = () => {
    let prevModuleIdx;
    let prevChapterIdx;

    let thisChapter = totalState.chapters.filter((chapter, i) => {
      if (chapter.id === currentChapter) {
        prevChapterIdx = i - 1;
        return chapter;
      }
    })[0];

    let thisModule = thisChapter.modules.filter((module, i) => {
      if (module.id === currentModule) {
        prevModuleIdx = i - 1;
        return module;
      }
    })[0];

    if (prevModuleIdx >= 0) {
      let prevModule = thisChapter.modules.filter(
        (module, i) => i === prevModuleIdx
      )[0];
      setupCurrent(thisChapter.id, prevModule.id);
      setCurrentChapter(thisChapter.id);
      setCurrentModule(prevModule.id);
    } else {
      if (prevChapterIdx >= 0) {
        let prevChapter = totalState.chapters.filter(
          (chapter, i) => i === prevChapterIdx
        )[0];
        let lastModule = prevChapter.modules.filter(
          (module, i) => i === prevChapter.numberOfModules - 1
        )[0];
        setupCurrent(prevChapter.id, lastModule.id);
        setCurrentChapter(prevChapter.id);
        setCurrentModule(lastModule.id);
      }
    }
  };

  const showNext = () => {
    let currentChapterIdx;
    let currentModuleIdx;

    let thisChapter = totalState.chapters.filter((chapter, i) => {
      if (chapter.id === currentChapter) {
        currentChapterIdx = i;
        return chapter;
      }
    })[0];
    let thisModule = thisChapter.modules.filter((module, i) => {
      if (module.id === currentModule) {
        currentModuleIdx = i;
        return module;
      }
    })[0];

    if (
      currentChapterIdx === totalState.numberOfChapters - 1 &&
      currentModuleIdx === thisChapter.numberOfModules - 1
    ) {
      return false;
    } else {
      return true;
    }
  };

  const showPrev = () => {
    let currentChapterIdx;
    let currentModuleIdx;

    let thisChapter = totalState.chapters.filter((chapter, i) => {
      if (chapter.id === currentChapter) {
        currentChapterIdx = i;
        return chapter;
      }
    })[0];
    let thisModule = thisChapter.modules.filter((module, i) => {
      if (module.id === currentModule) {
        currentModuleIdx = i;
        return module;
      }
    })[0];

    if (currentChapterIdx === 0 && currentModuleIdx === 0) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <div>
      <Header></Header>
      <div className="min-h-80 h-full  p-10 md:p-16 flex flex-col-reverse x-sm:space-y-10 md:space-y-0 md:flex-row md:space-x-12">
        {!currentModule && (
          <div className="text-orange w-2/3 flex flex-row space-x-4 text-center items-center justify-center font-extrabold md:text-lg lg:text-2xl 2xl:text-4xl">
            <p>إبدا الدورة الأن بإختيار درس</p>
            <IconContext.Provider
              value={{ size: "1em", style: { color: "#d29e83" } }}>
              <AiOutlineArrowRight></AiOutlineArrowRight>
            </IconContext.Provider>
          </div>
        )}
        {currentModule && (
          <div className="flex flex-col x-sm:w-full md:w-2/3 space-y-4 md:text-lg lg:text-2xl 2xl:text-4xl">
            {currentUrl && (
              <VideoPlayer next={next} type={currentType} url={currentUrl} />
            )}
            <div className="flex flex-col rtl">
              {(currentAttachment.files.length > 0 ||
                currentAttachment.images.length > 0 ||
                (currentType === "video" &&
                  currentAttachment.audios.length > 0)) && (
                <div className="mb-4 w-full flex flex-row items-center justify-center space-x-2 bg-gradient-to-r from-purple-900 to-pink-400 text-white text-center rounded-lg x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl">
                  مرفقات
                </div>
              )}
              {currentAttachment.files.length > 0 &&
                currentAttachment.files.map((item, i) => {
                  let where;

                  item.url === ""
                    ? (where = item.file_content)
                    : (where = item.url);
                  return (
                    <div className="flex items-center">
                      <p className="ml-2 md:ml-8 "> هنا رابط للملف :</p>
                      <a
                        href={
                          where.includes(
                            "farasha.smartiniaclient.com/media/courses/contents"
                          )
                            ? `https://docs.google.com/viewerng/viewer?url=${where}`
                            : where
                        }
                        target="blank">
                        <img
                          src="/images/pdf.svg"
                          alt="pdf_icon"
                          className="w-12 lg:w-24 xl:w-36 h-auto"
                        />
                      </a>

                      <a
                        href={
                          where.includes(
                            "farasha.smartiniaclient.com/media/courses/contents"
                          )
                            ? `https://docs.google.com/viewerng/viewer?url=${where}`
                            : where
                        }
                        target="blank"
                        className="text-gray-700 x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl underline cursor-pointer">
                        {item.title}
                      </a>
                    </div>
                  );
                })}
              {currentAttachment.images.length > 0 &&
                currentAttachment.images.map((item, i) => {
                  let where;
                  item.url === ""
                    ? (where = item.image_content)
                    : (where = item.url);
                  return (
                    <div className="flex items-center">
                      <p className="ml-2 md:ml-8 "> هنا رابط للملف :</p>
                      <a href={where} target="_blank" rel="noopener noreferrer">
                        <img
                          src="/images/picture.svg"
                          alt="pdf_icon"
                          className="w-12 lg:w-24 xl:w-36 h-auto"
                        />
                      </a>
                      <a
                        href={where}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-700 x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl underline cursor-pointer">
                        {item.title}
                      </a>
                    </div>
                  );
                })}
              {currentType === "video" &&
                currentAttachment.audios.length > 0 &&
                currentAttachment.audios.map((item, i) => {
                  let where;
                  item.url === ""
                    ? (where = item.audio_content)
                    : (where = item.url);
                  return (
                    <div className="flex items-center">
                      <p className="ml-2 md:ml-8 "> هنا رابط للملف :</p>
                      <a href={where} target="_blank" rel="noopener noreferrer">
                        <img
                          src="/images/volume.svg"
                          alt="pdf_icon"
                          className="w-12 lg:w-24 xl:w-36 h-auto"
                        />
                      </a>
                      <a
                        href={where}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-gray-700 x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl underline cursor-pointer">
                        {item.title}
                      </a>
                    </div>
                  );
                })}
            </div>
            <div className="">
              {showPrev() && (
                <button
                  className="bg-gradient-to-r from-purple-900 to-pink-400 text-white x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl rounded shadow-xl w-2/12 float-left"
                  onClick={previous}>
                  السابق
                </button>
              )}
              {showNext() && (
                <button
                  className="bg-gradient-to-r from-purple-900 to-pink-400 text-white x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl rounded shadow-xl w-2/12 float-right"
                  onClick={next}>
                  التالي
                </button>
              )}
            </div>
            {currentModule && (
              <Reviews
                setLoading={setLoading}
                courseId={data.id}
                moduleId={currentModule}
              />
            )}
          </div>
        )}
        <ChapterItems
          courseInfo={
            data && data.course_information ? data.course_information : null
          }
          setCurrentChapter={setCurrentChapter}
          currentChapter={currentChapter}
          setCurrentModule={setCurrentModule}
          currentModule={currentModule}
          setupCurrent={setupCurrent}
          chapters={totalState.chapters}></ChapterItems>
      </div>
      <Footer></Footer>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default CourseStudyPage;

// https://www.w3schools.com/html/mov_bbb.mp4
// https://www.youtube.com/watch?v=ysz5S6PUM-U
