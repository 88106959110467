import React from "react";
import Header from "../Header";
import Footer from "../Footer";
import PhoneAndroidIcon from "@material-ui/icons/PhoneAndroid";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import WebIcon from "@material-ui/icons/Web";
import StoreIcon from "@material-ui/icons/Store";
import ShopIcon from "@material-ui/icons/Shop";
import ListAltIcon from "@material-ui/icons/ListAlt";
import WbIncandescentIcon from "@material-ui/icons/WbIncandescent";
import CardTravelIcon from "@material-ui/icons/CardTravel";
import ThumbUpIcon from "@material-ui/icons/ThumbUp";

const Page = () => {
  return (
    <div>
      <Header />
      <div className="min-h-80 w-11/12 lg:w-1/2 mx-auto flex flex-col text-center my-12 space-y-4 rtl text-sm md:text-base lg:text-xl lg:min-w-1000">
        <div className="flex justify-center">
          <img src="/images/logo.png" alt=" logo" />
        </div>
        <div className="flex justify-center">
          <h1 className="text-3xl md:text-5xl lg:text-7xl text-farasha-purple my-4 font-Jannat">
            EL FARASHA ASCENTION
          </h1>
        </div>
        <ul>
          <li className="font-extrabold text-2xl underline text-farasha-purple">
            - من نحن ؟
          </li>
        </ul>
        <p>
          نحن مؤسسة الفراشة اسانشن مركز للتنمية والتطوير الذاتي، المتواجد
          بالجزائر العاصمة بالمركز التجاري المحمدية مول المحمدية – الجزائر
          العاصمة وفق رقم السجل التجاري رقم 00 / 12127203أ 18
        </p>
        <p>
          تم إنشاء المركز منذ 27/08/2018 من قبل السيدة رشا مشتة ، ويهدف إلى
          تعميم ونشر مفهوم التنمية الذاتية و الوعي التي هي في الوقت الحاضر غير
          متداولة بكثرة في المجتمع العربي وبالأخص المغرب العربي. و ذلك من خلال
          أنشطة ثقافية وإنسانية متمثلة في دورات و ورشات تدريبية, لقائات, رحلات و
          غيرها ، وبالتأكيد يحدث ذلك في ظل عمل منظم ومتقن من قبل فريق المؤسسة
          المتكامل ،الجميع يسعون بكل طاقاتهم وجهودهم بهدف تلبية حاجات العملاء
          وإرضاءهم .
        </p>
        <p>
          لمزيد من التفاصيل حول خدماتنا، مواقعنا, و وسائل التواصل الخاصة بنا,
          قمنا بارفاق ملفات و صور مع الايميل نتشرف باطلاعكم عليها.
        </p>
        <p className="font-bold text-2xl underline">
          انضع أيضا تحت تصرفكم معلومات الاتصال بنا.
        </p>
        <div>
          <PhoneAndroidIcon style={{ color: "#128C7E" }} />
          Tel & Whatsapp
          <WhatsAppIcon style={{ color: "#128C7E" }} />
        </div>
        <p className="font-bold">00213558.31.88.41 </p>
        <div className="flex flex-col items-center border inline p-2 border-black lg:w-1/2 mx-auto">
          <FacebookIcon style={{ color: "#4267B2" }} />
          <a
            href="https://www.facebook.com/farashaascension/"
            target="_blank"
            style={{ color: "#4267B2" }}>
            https://www.facebook.com/farashaascension
          </a>
          <p>FarashaAscension@</p>
        </div>
        <div className="flex flex-col items-center border inline p-2 border-black lg:w-1/2 mx-auto">
          <InstagramIcon style={{ color: "#4267B2" }} />
          <a
            href="https://www.instagram.com/farashaascension"
            target="_blank"
            style={{ color: "#4267B2" }}>
            https://www.instagram.com/farashaascension
          </a>
          <p>FarashaAscension@</p>
        </div>
        <div className="flex flex-col items-center border inline p-2 border-black lg:w-1/2 mx-auto">
          <WebIcon style={{ color: "#4267B2" }} />
          <a
            href="https://farashawake.com/"
            target="_blank"
            style={{ color: "#4267B2" }}>
            https://www.farashawake.com
          </a>
        </div>
        <p className="text-right font-extrabold text-2xl underline text-farasha-purple">
          {" "}
          <PhoneAndroidIcon /> خدمات الاتصال و التواصل:
        </p>
        <ul className="text-right">
          <li>
            - إدارة و الإشراف على وسائل التواصل الاجتماعي وخدمات الزبائن عن طريق
            الواتساب{" "}
          </li>
          <li>- الاستقبال والتواصل مع العملاء عن طريق مكالمات الهاتفية </li>
          <li>- متابعة اللوائح البريدية والبريد الإلكتروني </li>
          <li>- متابعة تسجيلات وآراء العملاء </li>
          <li>- جمع وتحديث قاعدة البيانات </li>
          <li>
            - المحافظة على التواصل مع العملاء عن طريق استمرارية رسائل البريد
            الإلكتروني التي تخص العروض الجديدة للورشات التنمية الذاتية ومختلف
            النشاطات .
          </li>
        </ul>
        <p className="text-right font-extrabold text-2xl underline text-farasha-purple">
          {" "}
          <StoreIcon /> خدمات تسويقية:
        </p>
        <ul className="text-right">
          <li>
            - التسويق الرقمي عن طريق نشر الاعلانات المتضمنة للصور و فيديوهات
            ومتابعة شاملة بواسطة صفحات الفايسبوك وانستغرام وكذلك منصاتنا
            الإلكترونية الحديثة التي تلعب دورا هاما في جمع وجذب العملاء .
          </li>
          <li>-تسويق VIP خاص بالعملاء المخلصين للمركز </li>
          <li>
            - تصميم ملصقات ولافتات و إعلانات و فيديوهات حول الورشات والرحلات و
            النشاطات بكافة أنواعها.{" "}
          </li>
          <li>
            - استغلال قاعدة البيانات للتسويق و اعلام العملاء بجديد المركز{" "}
          </li>
        </ul>
        <p className="text-right font-extrabold text-2xl underline text-farasha-purple">
          {" "}
          <ShopIcon /> خدمات تجارية :
        </p>
        <ul className="text-right">
          <li>
            - توفير وتأمين قاعات خاصة بالمؤتمرات و المحاضرات وكذللك ضمان المبيت
            للمدربين و المشاركين في المحاضرات إذا لزم الأمر وذللك وفق أفضل
            الأسعار.
          </li>
          <li>
            - توفير جميع المستلزمات المتعلقة بالتدريب وذللك وفق احتياجات وطلب
            المدرب (خدمة الطباعة بالشراكة مع محلات الطباعة عالية الجودة (افضل
            علاقة الجودة / السعر)
          </li>
        </ul>
        <p className="text-right font-extrabold text-2xl underline text-farasha-purple">
          {" "}
          <ListAltIcon /> خدمات تنظيمية:
        </p>
        <ul className="text-right">
          <li>
            - تكليف فريق محترف مكون من مجموعات من الشباب مليئة بالطاقة الإيجابية
            والحماس، مدربة لضمان الديناميكية والسير الجيد، الود والترحيب الحار.
          </li>
          <li>
            - إعداد قاعات التدريب في يوم الحدث، يليه سير العمل بسلاسة وفقًا
            لموضوع الحدث وطلب المدرب والجمهور.
          </li>
          <li>
            - عرض وبيع منتجات المدربين (كتب، قرص فلاش، خدمة عبر الإنترنت....)
            ومتابعة العملاء في حالة الحاجة (بعد الحدث).
          </li>
        </ul>
        <p className="text-right font-extrabold text-2xl underline text-farasha-purple">
          <WbIncandescentIcon /> - خدمة التصميم والتسويق الخاصة
        </p>
        <ul className="text-right">
          <li>
            1- نوفر لكم خدمة تصميم وتطوير المواقع الالكترونية وخدمات الاستضافة
            وحلول الويب تتظمن هذه الخدمة تصميم وانشاء المواقع الالكترونية باحدث
            التقنيات والاساليب الفنية في التصميم وكذلك بانشاء الموقع بما يتناسب
            مع متطلبات محركات البحث او ما يسمى SEO
          </li>
          <li>
            2-خدمة التسويق الالكتروني للمؤسسات والشركات عبر العديد من وسائل
            التسويق الالكتروني ومواقع التواصل الاجتماعي وذلك بانشاء حملات
            اعلانية موجهة الى شرائح العملاء المستهدفين
          </li>
        </ul>
        <p className="text-right font-extrabold text-2xl underline text-farasha-purple">
          <CardTravelIcon /> رحلات- الجزائر – الوعي :
        </p>
        <ul className="text-right">
          <li>
            --من دواعي سرورنا تنظيم الرحلات التي تجمع بين السياحة والتنمية
            الذاتية والرياضة (المشي لمسافات طويلة والسباحة والتخييم واليوغا
            والتأمل ....) تتخللها مناقشات عميقة في مجال الوعي الذاتي, والتمارين
            العملية و الألعاب الفكرية في جو مليء بالبهجة والاستمتاع والسرور و
            الطاقة الإيجابية.
          </li>
          <li>
            - نقوم بمناقشة أكثر الكتب تأثيراً ونتبادل الأفكار حول اهم المواضيع
            التي تخض الوعي و المجتمع التي تلهمنا للتطور و المضي قدما في الحياة .
          </li>
        </ul>
        <p className="text-right font-extrabold text-2xl underline text-farasha-purple">
          {" "}
          <ThumbUpIcon /> فرص للتعاقد والشراكة:{" "}
        </p>
        <p className="text-right">
          يتيح لك مركز الفرشة اسانشن الفرصة للعمل بشكل تعاوني ، إذا كان لديك
          منتجات للبيع أو العرض ، وأيضًا إذا كنت ترغب في توسيع سوقك ، فإننا نحرص
          على تقديم عملك وتواصل معنا .
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default Page;
