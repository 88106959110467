import React, { useState } from "react";
import Swiper from "../Swiper";
import { Link } from "react-router-dom";
import { ReactComponent as SvgProgram } from "../../../assets/titlesSvgProgram.svg";

const Programs = () => {
  return (
    <div className="mt-12">
      <div className="text-center font-bold mb-8    relative ">
        <SvgProgram className="mx-auto w-3/4 md:w-1/2 2xl:w-1/3 h-auto"></SvgProgram>
      </div>

      <Swiper />
    </div>
  );
};

export default Programs;
