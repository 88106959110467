import React, { useState } from "react";
import { ReactComponent as SvgTitles } from "../../../assets/titlesSvgStartWithUs.svg";
import { Link } from "react-router-dom";

const FreeContent = () => {
  return (
    <div className="mt-10">
      <div className="text-center font-bold text-base relative">
        <SvgTitles className="mx-auto w-3/4 md:w-1/2 2xl:w-1/3 h-auto"></SvgTitles>
      </div>
      <div className="flex  py-8 flex-col md:flex-row">
        <div className="flex-45 relative mb-4 md:mb-0">
          <img
            src="images/paid2.png"
            alt="journey"
            className="w-10/12 block mx-auto"
          />
        </div>
        <div className="flex-35 flex flex-col items-center justify-center text-white ">
          <Link
            to="/free-courses"
            className="bg-light-orange text-purple px-8 py-4 rounded-xl mb-12  text-center text-2xl 2xl:text-5xl font-DimaShekari">
            المحتوى المجاني
          </Link>
          <p className="text-farasha-purple text-base 2xl:text-2xl 2xl:leading-loose max-w-xl text-center md:text-right leading-8 xl:leading-10">
            هديتي لك لتبدأ صفحة جديدة في حياتك، وحتى تستطيع رسم خطوات جديدة
            واضحة لابد لك من ان تسامح/ أن تغفر!! أمسية الغفران المجانية ستساعدك
            على محو ما مضى والانتقال لنسخة جديدة منك اكثر خفة، اكثر إيجابية،
            واكثر وضوح
          </p>
          <Link
            to="/free-courses"
            className="mt-4 cursor-pointer bg-purple  py-2 px-6 xl:py-4 xl:px-12 rounded-4xl font-black text-primary text-white text-base font-DimaShekari">
            تحصل على هديتك الان{" "}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default FreeContent;
