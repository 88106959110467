import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, {
  Navigation,
  Pagination,
  Scrollbar,
  Autoplay,
} from "swiper/core";

// Import Swiper styles
import "swiper/swiper-bundle.css";
import "swiper/swiper.scss";
import "swiper/components/navigation/navigation.scss";
import useSWR from "swr";
import { fetcher } from "../../services/fetcher";
import { Link } from "react-router-dom";
import CircularProgress from "@material-ui/core/CircularProgress";

/* const courseHardCoded = {
  course_information: "",
  course_video: "",
  created_at: "2021-06-11T14:51:51.553459Z",
  duration: 0,
  id: 39,
  is_locked: false,
  nb_files: 2,
  nb_videos: 0,
  outside_price: 35,
  picture: "http://farasha.smartiniaclient.com/media/courses/pictures/8GBe6aiQ.jpeg",
  price: 3900,
  rating: 0,
  reviews: [],
  slug: "إنآنا-طاقة-الانوثة",
  students_count: 3,
  title: "إنآنا / طاقة الانوثة",
  total_rating: 0,
  type: "paid",
  updated_at: "2021-06-11T18:31:26.960664Z",
}; */

const SwiperComponent = () => {
  SwiperCore.use([Navigation, Pagination, Scrollbar, Autoplay]);

  const { data, error } = useSWR(
    `https://farasha.smartiniaclient.com/courses/paid/`,
    fetcher
  );
  data && console.log(data.filter((course) => !course.is_locked));
  if (!data)
    return (
      <div className="flex justify-center items-center h-20">
        <CircularProgress style={{ color: "#492b83" }} />
      </div>
    );
  if (data)
    return (
      <Swiper
        spaceBetween={20}
        navigation
        slidesPerView={3}
        breakpoints={{
          0: {
            slidesPerView: 1,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 2,
          },
          // when window width is >= 768px
          768: {
            slidesPerView: 3,
          },
        }}
        loop={true}
        autoplay={{ delay: 5000, disableOnInteraction: false }}
        pagination={{ clickable: true }}>
        {data
          .filter((course) => !course.is_locked)
          .map((course) => (
            <SwiperSlide key={course.id}>
              <Link to={`/paid-courses/course-detail/${course.slug}`}>
                <img
                  src={course.picture}
                  alt={course.title}
                  className="block mx-auto"
                />
              </Link>
            </SwiperSlide>
          ))}
        {/*      <SwiperSlide>
          <Link to={`/pack-courses`}>
            <img src={courseHardCoded.picture} />
          </Link>
        </SwiperSlide> */}
      </Swiper>
    );
};

export default SwiperComponent;
