import React, { useState } from "react";
import { Link } from "react-router-dom";

const Overview = () => {
  return (
    <div className="py-10 md:px-16 TopHomePage flex flex-col md:flex-row items-center justify-evenly gap-2">
      <div className="x-sm:w-2/3 md:w-1/2">
        <img src="images/imageTopHome (1).png" alt="home_image" />
      </div>
      <div className="text-white md:w-1/2 flex flex-col  justify-center items-center ">
        <p className="text-center text-gray-200 md:text-right text-base 2xl:text-2xl 2xl:leading-loose  xl:leading-9 max-w-md md:max-w-xl">
          اضحى التعليم التقليدي في الاسرة والمدارس والجامعات بلا معنى عميق و
          اضافة فعلية و حقيقية للانسان و البشرية. تواجدك في هذا الموقع ليس صدفة!
          بل هو رسالة لك لتخطو خطوة نحو بيئة جديدة وداعمة لتطورك ونجاحك على جميع
          الأصعدة والمجالات تمثل حقيقتك و تذكرك بأصالتك ونورك الصافي الذي اتيت
          منه. الوقت ليس متأخرا لتستيقظ و تتعلم هذه المهارات و العلوم و الاسرار
        </p>
        <Link
          to="/login"
          className="mt-4 cursor-pointer bg-purple  py-2 px-6 xl:py-4 xl:px-12 rounded-4xl font-black text-primary text-white text-base font-DimaShekari"
        >
          مرحبا بك معنا{" "}
        </Link>
      </div>
    </div>
  );
};

export default Overview;
