import React, { useState } from "react";
import { Link } from "react-router-dom";

const ArticlesOverview = () => {
  return (
    <div className="flex  py-8 flex-col md:flex-row-reverse mt-16">
      <div className="flex-45">
        <img
          src="images/55-copy.png"
          alt="journey"
          className="w-10/12 block mx-auto"
        />
      </div>
      <div className="flex-35 flex flex-col items-center justify-center  text-white ">
        <Link
          to="/articles"
          className="bg-light-orange text-orange px-8 py-4 rounded-xl my-8   text-center text-2xl 2xl:text-5xl font-DimaShekari"
        >
          المقالات
        </Link>
        <p className="text-farasha-purple  text-base 2xl:text-2xl 2xl:leading-loose max-w-xl text-center md:text-right leading-8 xl:leading-10">
          نقدم لك مجموعة مقالات متنوعة في مجال التنمية الذاتية و الوعي كتبها لك
          مختصون و باحثون في مجالات عدة تدور حول كل ما يخدم ارتقاء وتطور الفرد
          من علاقات، صحة، تحرر، ثراء،... إلخ مدونة غنية ومتنوعة اهديناها لك بكل
          حب
        </p>
        <Link
          to="/articles"
          className="mt-4 cursor-pointer bg-purple  py-2 px-6 xl:py-4 xl:px-12 rounded-4xl font-black text-primary text-white text-base font-DimaShekari"
        >
          ابدأ القراءة الان{" "}
        </Link>
      </div>
    </div>
  );
};

export default ArticlesOverview;
