import React from "react";
import Header from "./../Header";
import Footer from "./../Footer";
import Cart from "./content/Cart";

const CartPage = () => {
  return (
    <div>
      <Header></Header>
      <Cart></Cart>
      <Footer></Footer>
    </div>
  );
};

export default CartPage;
