import React, { useState } from "react";
import { ReactComponent as ArticlesSvg } from "../../assets/articlesSvg.svg";
import Header from "./../Header";
import Footer from "./../Footer";

import { IconContext } from "react-icons";
import { AiOutlineDown } from "react-icons/ai";
import useSWR, { mutate } from "swr";
import { fetcher } from "../../services/fetcher";

import cn from "classnames";
import ArticleCard from "./ArticleCard";

const ArticlesPage = () => {
  const categories = useSWR(
    `https://farasha.smartiniaclient.com/blog/categories/`,
    fetcher
  );

  const [blogsUrl, setBlogsUrl] = useState(
    `https://farasha.smartiniaclient.com/blog/posts/`
  );

  const { data, error } = useSWR(blogsUrl, fetcher);
  const [currentCategory, setCurrentCategory] = useState("الكل");

  const [menuOpen, setMenuOpen] = useState(false);
  let menuStyle = cn("relative cursor-pointer", { dropdownBlog: menuOpen });

  let menuItemsStyle = cn(
    "mt-2 w-1/5 text-white rounded-lg p-2 absolute hidden top-8 ",
    {
      dropdownContentBlog: menuOpen,
    }
  );

  return (
    <div>
      <Header></Header>
      <div className="min-h-80 h-full">
        <div className="mt-6">
          <div className="text-center font-bold text-base mb-4 relative">
            <ArticlesSvg className="mx-auto w-3/4 md:w-1/3 2xl:w-1/4 h-auto"></ArticlesSvg>
          </div>
        </div>
        <div className="h-full x-sm:p-4 md:px-20 space-y-20">
          <div className="pt-6 flex flex-row items-center justify-center">
            <div className={`w-1/2 ${menuStyle}`}>
              <button
                onClick={() => setMenuOpen(!menuOpen)}
                className="self-start flex flex-row items-center justify-center space-x-2 bg-white text-reghda-blue font-extrabold p-2 px-8 rounded-xl x-sm:text-sm md:text-base 2xl:text-4xl outline-none focus:outline-none shadow-xl">
                <span>
                  <IconContext.Provider
                    value={{ size: "1em", style: { color: "#160c3e" } }}>
                    <AiOutlineDown></AiOutlineDown>
                  </IconContext.Provider>
                </span>
                <span> {currentCategory} </span>
              </button>
              <div className={menuItemsStyle}>
                {categories.data &&
                  [{ id: 0, title: "الكل" }, ...categories.data].map(
                    (category) => (
                      <div
                        onClick={() => {
                          if (category.id === 0) {
                            setBlogsUrl(
                              `https://farasha.smartiniaclient.com/blog/posts/`
                            );
                            setMenuOpen(!menuOpen);
                            setCurrentCategory(category.title);
                            return;
                          }

                          setBlogsUrl(
                            `https://farasha.smartiniaclient.com/blog/posts/?category=${category.id}`
                          );
                          setMenuOpen(!menuOpen);
                          setCurrentCategory(category.title);
                          return;
                        }}
                        className="text-white bg-blue-night  py-2 flex flex-row space-x-1 items-center justify-center cursor-pointer">
                        <span className="text-sm text-center">
                          {category.title}
                        </span>
                      </div>
                    )
                  )}
              </div>
            </div>
            <div className="w-1/2 text-right text-reghda-blue x-sm:text-lg sm:text-2xl lg:text-4xl 2xl:text-6xl font-bold"></div>
          </div>
          <div className="w-full h-full grid x-sm:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4s gap-4">
            {data &&
              data.map((article) => (
                <ArticleCard article={article}></ArticleCard>
              ))}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default ArticlesPage;
