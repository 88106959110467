import React, { useState } from "react";
import { ReactComponent as PaidCoursesSvg } from "../../assets/paidCoursesSvg.svg";
import Header from "./../Header";
import Footer from "./../Footer";

import useSWR, { mutate } from "swr";
import { fetcher } from "../../services/fetcher";
import CourseCard from "../CourseCard";

const PaidCoursesPage = () => {
  const [blogsUrl, setBlogsUrl] = useState(
    `https://farasha.smartiniaclient.com/courses/paid/`
  );
  const { data, error } = useSWR(blogsUrl, fetcher);
  data && console.log(data);
  return (
    <div>
      <Header></Header>
      <div className="min-h-80 h-full">
        <div className="mt-12">
          <div className="text-center font-bold text-base mb-8 relative">
            <PaidCoursesSvg className="mx-auto w-3/4 md:w-1/3 2xl:w-1/4 h-auto"></PaidCoursesSvg>
          </div>
        </div>
        <div className="h-full x-sm:p-4 md:px-20 space-y-20">
          <div className="w-full h-full grid x-sm:grid-cols-1 sm:grid-cols-2 md:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-4s gap-4">
            {data &&
              data
                .filter((course) => !course.is_hidden)
                .map((course) => <CourseCard course={course}></CourseCard>)}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
};

export default PaidCoursesPage;
