import React from "react";
import Header from "../Header";
import CourseTier from "./content/CourseTier";
import Footer from "../Footer";

const MyCoursePage = () => {
  return (
    <>
      <Header />
      <CourseTier title="قائمة دوراتي"></CourseTier>
      <Footer />
    </>
  );
};

export default MyCoursePage;
