import React, { useState } from "react";
import { IconButton } from "@material-ui/core";
import TwitterIcon from "@material-ui/icons/Twitter";
import YouTubeIcon from "@material-ui/icons/YouTube";
import FacebookIcon from "@material-ui/icons/Facebook";
import InstagramIcon from "@material-ui/icons/Instagram";
import WhatsAppIcon from "@material-ui/icons/WhatsApp";

import { addToNewsletter } from "../services/newsletter";
import LoaderModal from "./LoaderModal";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { Link } from "react-router-dom";

const Footer = () => {
  const [loading, setLoading] = useState(false);

  const [email, setEmail] = useState("");
  const validationSchema = Yup.object({
    email: Yup.string()
      .email("عنوان البريد الإلكتروني غير صالح")
      .required("هذا الحقل مطلوب"),
  });

  return (
    <div className="text-center text-white bg-farasha-dark-purple">
      <div className="p-4 flex flex-col-reverse md:flex-row items-center justify-around">
        <div className="x-sm:w-full md:w-1/3 text-center ">
          <div>
            <a
              href="https://smartinia.com/"
              target="_blank"
              rel="noreferrer"
              className="flex flex-row space-x-4 items-center justify-center mt-6 ">
              <img
                src="/images/smartinia.png"
                className="x-sm:h-6 sm:h-10"
                alt="icon"
              />
              <p className="x-sm:text-xs md:text-sm lg:text-lg 2xl:text-2xl">
                تم الانشاء بواسطة{" "}
              </p>
            </a>
          </div>
          <div className="flex flex-row items-center justify-center">
            <h6 className="my-6 x-sm:text-sm md:text-base lg:text-lg 2xl:text-2xl">
              الإشتراك بالنشرة البريدية
            </h6>
          </div>
          <div class="my-6 flex w-full">
            <input
              onChange={(event) => setEmail(event.target.value)}
              value={email}
              name="newsletter"
              className="border-2 rounded-l-full px-4 py-2 w-full text-right text-gray-600 x-sm:text-xs lg:text-lg outline-none focus:outline-none"
              type="text"
              placeholder="البريد الالكتروني"
            />
            <button
              onClick={async () => {
                if (validationSchema.isValidSync({ email })) {
                  setLoading(true);
                  const res = await addToNewsletter({
                    email: email,
                    list_name: "Newsletters",
                  });
                  setLoading(false);
                  console.log(res, "🎉");
                  if (res && res.status === 201) {
                    toast("تم تسجيلك في النشرة البريدية بنجاح ✔");
                  } else {
                    toast("هذا البريد الإلكتروني مسجل من قبل");
                  }
                } else {
                  toast("يجب عليك إدخال بريد إلكتروني صحيح");
                }
                setEmail("");
              }}
              className="text-sm rounded-r-full px-4 py-2 bg-orange whitespace-no-wrap outline-none focus:outline-none">
              إشتراك
            </button>
          </div>
          <div className="w-full flex flex-col space-y-5 md:space-y-0 md:flex-row items-center justify-around">
            <div className="flex flex-row items-center justify-around">
              <a
                href="https://twitter.com/Rashameshta"
                rel="noopener noreferrer"
                target="_blank">
                <IconButton style={{ color: "white" }}>
                  <TwitterIcon />
                </IconButton>
              </a>
              <a
                href="https://www.youtube.com/channel/UCT8ZKQUQcScBWHr-2_SdaLw"
                rel="noopener noreferrer"
                target="_blank">
                <IconButton style={{ color: "white" }}>
                  <YouTubeIcon />
                </IconButton>
              </a>
              <a
                href="https://web.facebook.com/farashaascension/?_rdc=1&_rdr"
                rel="noopener noreferrer"
                target="_blank">
                <IconButton style={{ color: "white" }}>
                  <FacebookIcon />
                </IconButton>
              </a>
              <a
                href="https://www.instagram.com/rashameshta/"
                rel="noopener noreferrer"
                target="_blank">
                <IconButton style={{ color: "white" }}>
                  <InstagramIcon />
                </IconButton>
              </a>
              <a
                href="https://api.whatsapp.com/send?phone=213558318841"
                rel="noopener noreferrer"
                target="_blank">
                <IconButton style={{ color: "white" }}>
                  <WhatsAppIcon />
                </IconButton>
              </a>
            </div>
            <div className="text-gray-200 text-center x-sm:text-xs 2xl:text-lg">
              2021 &copy; جميع الحقوق محفوظة لفراشة اسانشن
            </div>
          </div>
        </div>
        <div className="x-sm:hidden md:flex w-1/3 my-4 text-center">
          <div className="w-full flex flex-row items-center justify-center">
            <img
              className="x-sm:w-3/4 md:w-1/4"
              src="/images/logo_white.svg"
              alt="logo"
            />
          </div>
          {/* <a
            href="https://smartinia.com/"
            target="_blank"
            rel="noreferrer"
            className="flex flex-row space-x-4 items-center justify-center mt-6"
          >
            <img
              src="/images/footer/smartinia.png"
              className="x-sm:h-6 sm:h-10"
              alt="icon"
            />
            <p className="x-sm:text-xs md:text-sm lg:text-lg 2xl:text-2xl">
              تم الانشاء بواسطة{" "}
            </p>
          </a> */}
        </div>
        <div className="text-gray-200 w-full md:w-1/3 flex flex-row items-center justify-around space-x-4 my-4 text-center x-sm:text-xs 2xl:text-lg">
          <div className="w-1/2 flex flex-col items-center justify-around space-y-8">
            <Link to="/" className="w-full text-center">
              الرئيسية
            </Link>
            <Link to="/free-courses" className="w-full text-center">
              الدورات المجانية
            </Link>
            <Link to="/paid-courses" className="w-full text-center">
              الدورات المدفوعة
            </Link>
            <Link to="/articles" className="w-full text-center">
              المقالات
            </Link>
          </div>
          <div className="w-1/2 flex flex-col items-center justify-around space-y-8">
            <Link to="/return" className="w-full text-center">
              سياسة الإرجاع
            </Link>
            <Link to="/privacy-policy" className="w-full text-center">
              سياسة الخصوصية
            </Link>
            <Link to="/rules" className="w-full text-center">
              الشروط و الأحكام
            </Link>
            <Link to="/questions" className="w-full text-center">
              الأسئلة المتكررة
            </Link>
          </div>
        </div>
      </div>
      <LoaderModal modalIsOpen={loading}></LoaderModal>
    </div>
  );
};

export default Footer;
