import React from "react";
import Collapsible from "react-collapsible";
// import { Link, useLocation } from "react-router-dom";
import { IconContext } from "react-icons";
import { AiOutlineArrowDown } from "react-icons/ai";
import parse from "html-react-parser";
const ChapterItems = ({
  setCurrentChapter,
  currentChapter,
  setupCurrent,
  chapters,
  currentModule,
  setCurrentModule,
  courseInfo,
}) => {
  return (
    <div className="flex flex-col x-sm:w-full md:w-1/3">
      {courseInfo && (
        <div className=" text-xl text-orange  x-sm:leading-loose md:text-base md:leading-loose  lg:leading-loose  2xl:leading-loose">
          <h1 className="text-right mb-8 text-2xl lg:text-4xl 2xl:text-5xl">
            معلومات حول الدورة
          </h1>
          {parse(
            courseInfo.replaceAll(
              "<img ",
              `<img style='display: block; margin : 0 auto ;'`
            )
          )}
        </div>
      )}
      {chapters.map((chapter, i) => {
        return (
          <Collapsible
            trigger={
              <div className="w-full flex flex-row items-center justify-end space-x-2 bg-gradient-to-r from-purple-900 to-pink-400 text-white rounded-lg x-sm:p-1 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl cursor-pointer">
                <p>{chapter.title}</p>
                <IconContext.Provider
                  value={{ size: "1em", style: { color: "#fff" } }}
                >
                  <AiOutlineArrowDown></AiOutlineArrowDown>
                </IconContext.Provider>
              </div>
            }
            className="text-right x-sm:p-1 md:p-2 2xl:p-4 x-sm:space-y-0"
            openedClassName="text-right x-sm:p-1 md:p-2 2xl:p-4 x-sm:space-y-0"
          >
            {chapter.modules.map((module, idx) => {
              return (
                <p className="flex flex-col x-sm:p-0 md:p-2 2xl:p-4 x-sm:text-xxs sm:text-sm md:text-lg lg:text-xl 2xl:text-3xl">
                  <p
                    onClick={() => {
                      setCurrentChapter(chapter.id);
                      setCurrentModule(module.id);
                      setupCurrent(chapter.id, module.id);
                    }}
                    key={idx}
                    className={
                      module.id === currentModule
                        ? "text-farasha-purple p-2 cursor-pointer"
                        : "text-gray-400 p-2 cursor-pointer"
                    }
                  >
                    {/* ـ الدرس {idx + 1} */}
                    {module.title}
                  </p>
                </p>
              );
            })}
          </Collapsible>
        );
      })}
    </div>
  );
};

export default ChapterItems;
