import Axios from "axios";

export async function pay(data) {
  try {
    const response = await Axios.post(
      `https://farasha.smartiniaclient.com/payments/create-checkout-session`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}

export async function payPacks(data) {
  try {
    const response = await Axios.post(
      `https://farasha.smartiniaclient.com/payments/create-checkout-session/packs`,
      data
    );
    return response;
  } catch (error) {
    return { error, isError: true };
  }
}
