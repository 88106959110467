import React from "react";
import Header from "./../Header";
import Footer from "./../Footer";
import { IconContext } from "react-icons";
import { FaUserAlt } from "react-icons/fa";

import useSWR from "swr";
import { fetcher } from "../../services/fetcher";
import parse from "html-react-parser";
import { useParams } from "react-router-dom";

import Reviews from "./Reviews";

const ArticleContentPage = () => {
  const { slug } = useParams();
  const { data, error } = useSWR(
    `https://farasha.smartiniaclient.com/blog/post/${slug}`,
    fetcher
  );

  return (
    <>
      <Header></Header>
      <div className="min-h-80 h-full w-9/12 mx-auto mt-8 space-y-6 x-sm:py-5 md:py-10 2xl:py-16">
        <div className="w-full text-right text-orange x-sm:text-lg sm:text-2xl lg:text-4xl 2xl:text-6xl font-bold">
          <h1> {data && data.title} </h1>
        </div>
        <div className="w-full flex flex-row items-end justify-end space-x-4  x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl">
          {data && (
            <p className="text-gray-300">{data.created_at.substring(0, 10)}</p>
          )}
          {data && (
            <p className="font-extrabold text-orange">
              {`${data.author.family_name} ${data.author.given_name}`}
            </p>
          )}

          <div className="x-sm:text-sm sm:text-sm lg:text-base 2xl:text-lg">
            <IconContext.Provider
              value={{ size: "1.8em", style: { color: "#d29e83" } }}>
              <FaUserAlt></FaUserAlt>
            </IconContext.Provider>
          </div>
        </div>
        {data && <img className="w-full" src={data.image} alt={"desc"} />}
        {data && parse(data.body)}

        {data && <Reviews slug={slug} blogId={data.id} />}
      </div>
      <Footer></Footer>
    </>
  );
};

export default ArticleContentPage;
