import React, { useState } from "react";
import { ReactComponent as SvgWhoAreWe } from "../../../assets/titlesSvgWhoAreWe.svg";
import { Link } from "react-router-dom";

const AboutUs = () => {
  return (
    <div className="flex gap-2 py-8 flex-col md:flex-row-reverse ">
      <div className="flex flex-35 max-height-700 mb-8 md:mb-0">
        <img
          src="images/WhatsApp Image 2021-06-11 at 12.39.38.jpeg"
          alt="journey"
          className="  w-auto h-full block mx-auto max-height-700"
        />
      </div>
      <div className="flex-35 flex flex-col items-center justify-center text-white ">
        <div className="text-center font-bold  mb-8   relative ">
          <SvgWhoAreWe className="mx-auto w-3/4 h-auto" />
        </div>
        <p className="text-farasha-purple text-base 2xl:text-2xl 2xl:leading-loose max-w-xl text-center md:text-right leading-8 xl:leading-10 rtl">
          الفراشة أسانشن مؤسسة تأسست سنة 2018 من طرف اللايف كوتش د.رشا مشتة،
        </p>
        <p className="text-farasha-purple text-base 2xl:text-2xl 2xl:leading-loose max-w-xl text-center md:text-right leading-8 xl:leading-10">
          تعتبر المؤسسة، من خلال أنشطتها المتنوعة والاحترافية، بوابة تساعد الفرد
          على تطوير ذاته جسديا، فكريا مشاعريا وروحيا عن طريق مجموعة من الدورات (
          الحضورية و الاونلاين) والمنتجات والرائدة في مجال الوعي والتنمية
          الذاتية رسالتنا: المساهمة في رفع الوعي البشري بأساليب عصرية واحترافية
          قيمنا: العطاء، الوعي، المتعة، الاحترافية، الجودة واليسر
        </p>
        <Link
          to="/about-us"
          className="mt-4 cursor-pointer bg-purple  py-2 px-6 xl:py-4 xl:px-12 rounded-4xl font-black text-primary text-white text-base font-DimaShekari">
          تعرف علينا اكثر...{" "}
        </Link>
      </div>
    </div>
  );
};

export default AboutUs;
