import React, { useState } from "react";
import { Link } from "react-router-dom";

const PaidContent = () => {
  return (
    <div className="flex  py-8 flex-col md:flex-row ">
      <div className="flex-35 flex flex-col justify-center items-center text-white ">
        <Link
          to="/paid-courses"
          className="bg-light-orange text-purple px-8 py-4 rounded-xl mb-8  text-center text-2xl 2xl:text-5xl font-DimaShekari"
        >
          المحتوى المدفوع
        </Link>
        <div className="flex flex-col justify-center items-center">
          <p className="text-farasha-purple text-lg  2xl:text-2xl 2xl:leading-loose max-w-xl text-center md:text-center leading-8 xl:leading-10">
            بدأ الآن معنا رحلة التغيير
          </p>
          <p className="text-farasha-purple text-lg 2xl:text-2xl 2xl:leading-loose max-w-xl text-center md:text-center leading-8 xl:leading-10">
            عن طريق مجموعة مختارة من كورسات وامسيات تساعدك دون أدنى شك على تحقيق
            التغيير الذي تطمح إليه... ايا كان جنسك ايا كان عمرك فقط انظم الينا
          </p>
        </div>
      </div>
    </div>
  );
};

export default PaidContent;
