import React, { useState } from "react";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";

const ShippmentInfo = ({ setShippmentDetail, setInfo }) => {
  const phoneRegExp =
    /^((\\+[1-9]{1,1}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
  const validationSchema = Yup.object({
    firstname: Yup.string()
      .max(30, "يجب ان يكون 30 حرفاَ أو أقل")
      .required("هذا الحقل مطلوب"),
    lastname: Yup.string()
      .max(30, "يجب ان يكون 30 حرفاَ أو أقل")
      .required("هذا الحقل مطلوب"),
    phone: Yup.string()
      .matches(phoneRegExp, "رقم الهاتف خاطئ")
      .min(8, "رقم الهاتف خاطئ")
      .required("هذا الحقل مطلوب"),
    country: Yup.string()
      .max(15, "يجب ان يكون 15 حرفاَ أو أقل")
      .required("هذا الحقل مطلوب"),
    addressOne: Yup.string().required("هذا الحقل مطلوب"),
    addressTwo: Yup.string(),
  });

  return (
    <div className="w-full">
      <div className="bg-gray-50 text-right p-12 rounded-2xl shadow-2xl space-y-6 x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl">
        <p className="font-extrabold text-orange">أدخل معلومات الشحن</p>
        <Formik
          initialValues={{
            firstname: "",
            lastname: "",
            phone: "",
            country: "",
            addressOne: "",
            addressTwo: "",
          }}
          validationSchema={validationSchema}
          onSubmit={(values, { setSubmitting }) => {
            setShippmentDetail(values);
            setInfo(true);
            setSubmitting(false);
          }}
        >
          <Form className="flex flex-col w-full px-0 x-sm:space-y-4 md:space-y-6 2xl:space-y-12 text-right">
            <fieldset className="flex flex-col md:flex-row items-center justify-between x-sm:space-y-3 md:space-y-0 md:space-x-3 lg:space-x-6 2xl:space-x-12">
              <div className="w-full flex flex-col items-center justify-between space-y-4">
                <Field
                  name="firstname"
                  type="text"
                  placeholder="الإسم"
                  class="w-full border-2 border-gray-100 bg-gray-100 text-right p-1 lg:p-2 2xl:p-5 pr-6 rounded-2xl outline-none focus:outline-none focus:border-orange"
                />
                <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
                  <ErrorMessage name="firstname" />
                </div>
              </div>
              <div className="w-full flex flex-col items-center justify-between space-y-4">
                <Field
                  name="lastname"
                  type="text"
                  placeholder="اللقب"
                  class="w-full border-2 border-gray-100 bg-gray-100 text-right p-1 lg:p-2 2xl:p-5 pr-6 rounded-2xl outline-none focus:outline-none focus:border-orange"
                />
                <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
                  <ErrorMessage name="lastname" />
                </div>
              </div>
            </fieldset>
            <fieldset className="flex flex-col md:flex-row items-center justify-between x-sm:space-y-3 md:space-y-0 md:space-x-3 lg:space-x-6 2xl:space-x-12">
              <div className="w-full flex flex-col items-center justify-between space-y-4">
                <Field
                  name="phone"
                  type="text"
                  placeholder="الهاتف"
                  class="w-full border-2 border-gray-100 bg-gray-100 text-right p-1 lg:p-2 2xl:p-5 pr-6 rounded-2xl outline-none focus:outline-none focus:border-orange"
                />
                <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
                  <ErrorMessage name="phone" />
                </div>
              </div>

              <div className="w-full flex flex-col items-center justify-between space-y-4">
                <Field
                  name="country"
                  type="text"
                  placeholder="البلد"
                  class="w-full border-2 border-gray-100 bg-gray-100 text-right p-1 lg:p-2 2xl:p-5 pr-6 rounded-2xl outline-none focus:outline-none focus:border-orange"
                />
                <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
                  <ErrorMessage name="country" />
                </div>
              </div>
            </fieldset>
            <fieldset className="flex flex-row items-start justify-end">
              <div className="w-full flex flex-col items-center justify-between space-y-4">
                <Field
                  name="addressOne"
                  placeholder="العنوان"
                  type="text"
                  class="w-full border-2 border-gray-100 bg-gray-100 text-right p-1 lg:p-2 2xl:p-5 pr-6 rounded-2xl outline-none focus:outline-none focus:border-orange"
                />
                <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
                  <ErrorMessage name="addressOne" />
                </div>
              </div>
            </fieldset>
            <fieldset className="flex flex-row items-start justify-end">
              <div className="w-full flex flex-col items-center justify-between space-y-4">
                <Field
                  name="addressTwo"
                  placeholder="العنوان الثاني"
                  type="text"
                  class="w-full border-2 border-gray-100 bg-gray-100 text-right p-1 lg:p-2 2xl:p-5 pr-6 rounded-2xl outline-none focus:outline-none focus:border-orange"
                />
                <div className="text-red-400 border-red-400 md:text-sm 2xl:text-2xl">
                  <ErrorMessage name="addressTwo" />
                </div>
              </div>
            </fieldset>
            <div className="flex flex-row items-center justify-center">
              <button
                type="submit"
                className="bg-orange text-white rounded-xl py-2 x-sm:px-2 sm:px-4 md:px-6 lg:px-8 2xl:px-16 2xl:py-4 x-sm:text-sm sm:text-base lg:text-lg 2xl:text-2xl"
              >
                التالي
              </button>
            </div>
          </Form>
        </Formik>
      </div>
    </div>
  );
};

export default ShippmentInfo;
